import { IconClay, IconStone, IconWood } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types";

// Stick
export const resourceStick: Resource = {
  id: createId(),
  name: "Stick",
  type: "stick",
  rarity: "common",
  icon: IconWood,
  category: "basic",
  tradeValue: 1,
}

// Wood
export const resourceWood: Resource = {
  id: createId(),
  name: "Wood",
  type: "wood",
  rarity: "common",
  icon: IconWood,
  category: "basic",
  tradeValue: 1,
}

// Stone
export const resourceStone: Resource = {
  id: createId(),
  name: "Stone",
  type: "stone",
  rarity: "common",

  icon: IconStone,
  byproduct: undefined,
  category: "basic",

  tradeValue: 1,
}

// Clay
export const resourceClay: Resource = {
  id: createId(),
  name: "Clay",
  type: "clay",
  rarity: "common",

  icon: IconClay,
  byproduct: undefined,
  category: "basic",

  tradeValue: 1,
}

// Export const for referencing all Basic resources
export const basicResources: Resource[] = [resourceWood, resourceStone];
