import { resourceStone, resourceWood } from "../items/resources/basic";
import { Tech } from "../types/types";
import { hour } from "./_data";
import { IconBridge, IconRoad, IconTorch } from "./icons";

export const techTorch: Tech = {
  id: "torches",
  name: "Torches",
  icon: IconTorch,
  active: false,
  level: 1,
  description: "Illuminate your buildings at night.",
  cost: [{
    resource: resourceWood,
    amount: 2,
  }],
  progress: {
    current: 0,
    max: 10,
  }
}

export const techRoads: Tech = {
  id: "roads",
  name: "Roads",
  icon: IconRoad,
  active: false,
  level: 2,
  description: "Travel faster between your buildings on roads.",
  cost: [{
    resource: resourceStone,
    amount: 100,
  }],
  progress: {
    current: 0,
    max: hour * 8,
  }
}

export const techBridge: Tech = {
  id: "bridge",
  name: "Bridge",
  icon: IconBridge,
  active: true,
  level: 2,
  description: "Opens a way back to the city.",
  cost: [{
    resource: resourceStone,
    amount: 100,
  }],
  progress: {
    current: 0,
    max: hour * 8,
  }
}