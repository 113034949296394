import { IconCoin, IconGemstone } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types"

// Gold Coin
export const resourceGoldCoin: Resource = {
  id: createId(),
  name: "Gold Coin",
  type: "goldCoin",
  rarity: "common",
  icon: IconCoin,
  
  category: "treasury",
  
  tradeValue: 1,
};

// Silver Coin
/* export const resourceSilverCoin: Resource = {
  id: createId(),
  name: "Silver Coin",
  id: "silverCoin",
  clickAmount: 1,
  byproduct: undefined,
  income: 0,
  clickChance: 100,
  
  type: "treasury",
  
  consumable: true,
  tradeValue: undefined,
  renewable: false,
  location: undefined,
  quality: undefined,
  weatherDependency: undefined,
  decayRate: undefined,
  eventTriggers: undefined,
  harvestingTools: undefined,
}; */

// Gemstone
export const resourceGemstone: Resource = {
  id: createId(),
  name: "Gemstone",
  type: "gemstone",
  rarity: "common",
  
  icon: IconGemstone,
  category: "treasury",
  
  tradeValue: 1,
};

// Export const for referencing all Treasury resources
export const treasuryResources: Resource[] = [resourceGoldCoin, resourceGemstone];

