import 'aos/dist/aos.css'
import { Dispatch, ReactNode, SetStateAction, useState } from "react"
import { IconArrowBentLeft, IconRefresh } from '../data/icons'
import { getRandomLastName, getRandomName, getRandomNickname } from "../data/names"
import { femalePortraits, getRandomFemalePortrait } from "../data/portraitsFemale"
import { getRandomMalePortrait, malePortraits } from "../data/portraitsMale"
import { Data, Sex } from "../types/types"
import Button, { ButtonGroup, IconButton } from "./Button"

const NewGame = ({
  data,
  setData,
  togglePause,
  isGameRunning,
}: {
  data: Data
  setData: Dispatch<SetStateAction<Data>>
  togglePause: () => void
  isGameRunning: boolean
}) => {

  const [stepSex, setStepSex] = useState(false)
  const [stepName, setStepName] = useState(false)
  const [stepPortrait, setStepPortrait] = useState(false)

  const [temporaryMalePortrait, setTemporaryMalePortrait] = useState<string>(getRandomMalePortrait())
  const [temporaryFemalePortrait, setTemporaryFemalePortrait] = useState<string>(getRandomFemalePortrait())

  const chooseSex = (s: Sex) => {
    const d = { ...data }
    d.player.name = getRandomName(s)
    d.player.lastName = getRandomLastName()
    d.player.nickname = getRandomNickname()
    d.player.portrait = s === "male" ? temporaryMalePortrait : temporaryFemalePortrait
    d.player.sex = s
    setData(d)
    setStepSex(true)
  }

  const setName = () => {
    const d = { ...data }
    d.player.name = getRandomName(d.player.sex!)
    setData(d)
  }

  const setNickname = () => {
    const d = { ...data }
    d.player.nickname = getRandomNickname()
    setData(d)
  }

  const setLastName = () => {
    const d = { ...data }
    d.player.lastName = getRandomLastName()
    setData(d)
  }

  const setPortrait = (p: string) => {
    const d = { ...data }
    d.player.portrait = p;
    d.player.sex === "male" ? setTemporaryMalePortrait(p) : setTemporaryFemalePortrait(p)
    setData(d)
  }

  const startGame = () => {
    const d = { ...data }
    d.newGame = false
    setData(() => {
      togglePause()
      return d
    })
  }

  return (
    <div className="fixed z-[9999] top-0 right-0 bottom-0 left-0 bg-surface bg-pattern-black-linen text-gray-100 flex items-center justify-center">

      {!stepSex && <Box data={data} title="Who are you?">
        <div className="flex flex-col items-center gap-2 w-full">
          <p className="">Are you a <span className="text-primary">Lord</span> or a <span className="text-primary">Lady</span>?</p>
          <div className="flex gap-4">
            <div onClick={() => chooseSex("male")}><img src={temporaryMalePortrait} alt="" className="w-16 h-16 rounded-full border-2 border-primary hover:border-primary-dark transition-colors duration-500" /></div>
            <div onClick={() => chooseSex("female")}><img src={temporaryFemalePortrait} alt="" className="w-16 h-16 rounded-full border-2 border-primary hover:border-primary-dark transition-colors duration-500" /></div>
          </div>
        </div>
      </Box>}

      {stepSex && !(stepName) && data.player.name && data.player.nickname && data.player.lastName &&
        <Box data={data} title="What's your name?">
          <div className={`flex gap-4 w-full mb-8 `}>
            <NameField text={data.player.name!} onClick={() => setName()} />
            <NameField text={`"${data.player.nickname!}"`} onClick={() => setNickname()} />
            <NameField text={data.player.lastName!} onClick={() => setLastName()} />
          </div>
          <div className="flex gap-4">
            <Button onClick={() => setStepSex(false)} icon={IconArrowBentLeft} />
            <Button onClick={() => setStepName(true)}>That's my name!</Button>
          </div>
        </Box>
      }

      {stepSex && stepName && !stepPortrait &&
        <Box data={data} title="What do you look like?">
          <div className="flex gap-6 justify-center">
            <div className="w-full grid grid-cols-2 gap-1 h-[512px] overflow-y-auto scrollbar-thin relative">
              {(data.player.sex === "male" ? malePortraits : femalePortraits).map((portrait, i) => {
                return <img
                  key={i}
                  src={portrait}
                  onClick={() => setPortrait(portrait)}
                  className={`
                    hover:border hover:border-primary transition duration-500 
                    ${(portrait === temporaryFemalePortrait || portrait === temporaryMalePortrait) ? "border border-primary" : ""}
                  `}
                  alt=""
                />
              })}
            </div>
            <div className="flex flex-col gap-8 justify-start items-start">
              <div className="w-[300px] h-[300px] flex-shrink-0 surface-before">
                {data.player.portrait && <img src={data.player.portrait} alt="" className="w-full h-full object-contain relative" data-aos="fade" data-aos-duration="1000" />}
              </div>
              <ButtonGroup>
                <Button onClick={() => setStepName(false)} icon={IconArrowBentLeft} />
                <Button onClick={() => setStepPortrait(true)} disabled={data.player.portrait === undefined} >Looks like me!</Button>
              </ButtonGroup>
            </div>
          </div>
        </Box>
      }
      {stepSex && stepName && stepPortrait &&
        <Box data={data} title="Tis you?">
          <div className="w-[300px] h-[300px] surface-before">
            <img src={data.player.portrait} alt="player portrait" className="w-full h-full object-contain" data-aos="fade" />
          </div>
          <div className="text-lg my-4">{data.player.name} "{data.player.nickname}" {data.player.lastName}</div>
          <ButtonGroup>
            <Button onClick={() => setStepPortrait(false)} icon={IconArrowBentLeft} />
            <Button onClick={() => startGame()} >Start</Button>
          </ButtonGroup>
        </Box>
      }

    </div>
  )
}

export default NewGame

const NameField = ({ text, onClick }: { text: string, onClick: () => void }) => (
  <div className="flex flex-col items-center flex-1 text-center">
    <span>{text}</span>
    <IconButton onClick={onClick} icon={IconRefresh} transparent />
  </div>
)

const Box = ({
  data,
  title,
  children,
}: {
  data: Data
  title: string
  children: ReactNode
}) => {
  return (
    <div className="w-full max-w-screen-sm text-center" data-aos="fade" data-aos-duration="1000">
      <h2 className="mb-2 text-2xl text-primary">{title}</h2>
      <div className="bg-ornamental !py-16 px-4 flex flex-col gap-4 items-center justify-center">
        <div className="flex flex-col items-center gap-2 w-full">
          {children}
        </div>
      </div>
    </div>
  )
}