import { ReactNode, useState } from "react"
import { Plus } from "react-feather"
import { bgPeasants } from "../../data/backgrounds"
import { IconArrowUp, IconScrollOpen } from "../../data/icons"
import { getBuildingId, getEfficiency, getResourceId, getToolForBuilding } from "../../helper/dataHelper"
import { getRandomNumber } from "../../helper/mathHelper"
import { assignWorker, unemployWorker } from "../../helper/workerHelper"
import { Building, Data } from "../../types/types"
import { IconButton } from "../Button"
import { ToolDataTable } from "../DataTable"
import { Icon, IconTile } from "../Icon"
import Modal, { BuildingTitle } from "../Modal"
import TooltipCustom from "../TooltipCustom"
import WorkerPortrait from "../WorkerPortrait"

export interface MenuBuildingProps {
  building: Building
  data: Data
  setData: React.Dispatch<React.SetStateAction<Data>>
  children?: ReactNode
  bg?: string
}

const MenuBuilding = ({
  building,
  data,
  setData,
  children,
  bg,
}: MenuBuildingProps) => {

  const [workersOpen, setWorkersOpen] = useState(false)

  const buildingId = getBuildingId(building);
  const dataBuilding = data.buildings[buildingId];
  const maxLevel = dataBuilding.levels.length - 1;
  const level = dataBuilding.levels[dataBuilding.level];
  const hasMaxLevel = dataBuilding.level >= maxLevel

  // Declare nextLevel outside the function
  let nextLevel = dataBuilding.levels[dataBuilding.level + 1];
  const [workerImg] = useState(getRandomNumber(0, 3))

  const levelUp = () => {
    if (nextLevel) {
      const d = { ...data };
      const building = d.buildings[buildingId];

      const hasEnoughResources = nextLevel.cost?.every((cost) => {
        const resourceHave = data.resources[getResourceId(cost.resource)].length;
        const resourceNeed = cost.amount;
        return resourceHave >= resourceNeed;
      });

      if (hasEnoughResources && nextLevel.cost) {
        nextLevel.cost.forEach((c) => {
          const resourceType = getResourceId(c.resource);
          const resourceData = data.resources[resourceType];

          // Assuming resource is an array, we remove elements from the beginning
          resourceData.splice(0, c.amount);
        });

        building.level += 1;
        setData(d);
        nextLevel = dataBuilding.levels[building.level + 1];
        console.log("Building leveled up!");
      } else {
        console.log("Not enough resources to level up.");
      }
    } else {
      console.log("Maximum level reached.");
    }
  };

  const getBestWorker = () => data.unemployedWorkers.length > 0 ? data.unemployedWorkers.reduce((prev, current) => (prev && getEfficiency(prev, building) > getEfficiency(current, building)) ? prev : current) : undefined


  return (
    <div className="flex gap-4 flex-1 w-full justify-between max-h-full">
      <div className="justify-start items-start gap-4 flex flex-col flex-1">
        <div className="flex items-center gap-2">
          {level.jobs && level.jobs > 0 ? (
            <>
              <IconButton icon={IconScrollOpen} onClick={() => setWorkersOpen(true)}>Manage Assignments</IconButton>
              <Modal
                isOpen={workersOpen}
                setIsOpen={setWorkersOpen}
                bg={bgPeasants[workerImg]}
                data={data}
                title={<BuildingTitle building={building} />}>
                <div className="flex gap-4 justify-between items-start">

                  <div className="flex flex-col gap-4 items-start justify-start">
                    <div className="assigned-workers">
                      <div className="mb-1">Assigned Workers</div>
                      <div className="surface-box inline-flex gap-1">
                        {data.buildings[getBuildingId(building)].workers?.map((worker, i) => <WorkerPortrait worker={worker} building={building} onClick={() => unemployWorker(worker, data, setData)} key={i} isResting={!data.time.isWorktime} />)}
                        {[...Array(building.levels[building.level].jobs - building.workers.length)].map((_, i) => (
                          <>
                            <div data-tooltip-id="best-worker" key={i} className="bg-primary-dark rounded-sm w-12 h-12 inline-flex items-center justify-center" onClick={() => assignWorker(building, data, setData, getBestWorker())} >
                              <Plus size={24} />
                            </div>
                            <TooltipCustom id="best-worker">Assign best available worker.</TooltipCustom>
                          </>
                        ))}
                      </div>
                    </div>
                    <div className="available-workers">
                      <div className="mb-1">Available Workers</div>
                      <div className="surface-box inline-flex gap-1">
                        {data.unemployedWorkers?.map(worker => <WorkerPortrait worker={worker} building={building} onClick={() => assignWorker(building, data, setData, worker)} key={worker.id} />)}
                      </div>
                    </div>
                  </div>

                  <div className="available-tools overflow-y-auto scrollbar-thin">
                    <div className="mb-1">Available Tools</div>
                    <div className="grid grid-cols-4 gap-2 surface-box">
                      {data.toolItems.filter(t => t.type === getToolForBuilding(building)).map(tool => (
                        <IconTile item={tool}>
                          <ToolDataTable tool={tool} />
                        </IconTile>
                      ))}
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          ) : <></>}
          {!hasMaxLevel && (
            <IconButton icon={IconArrowUp} onClick={() => { levelUp() }} >
              <div className="flex flex-col gap-1">
                <div className="text-primary-light">Level up</div>
                <div className="flex flex-col gap-1">
                  {building.levels[building.level + 1].cost?.map((cost, i) => {
                    const resourceHave = data.resources[getResourceId(cost.resource)].length;
                    const resourceNeed = cost.amount;
                    const enough = resourceHave >= resourceNeed
                    return (
                      <div className={`flex items-center gap-2 ${enough ? "" : "text-red-400"}`} key={i}><Icon icon={cost.resource.icon} />{resourceHave}/{resourceNeed}</div>
                    )
                  })}
                </div>
                <div className="">Efficiency: {nextLevel.efficiency} (+{nextLevel.efficiency - level.efficiency})</div>
                <div className="">Workplaces: {nextLevel.jobs} (+{nextLevel.jobs - level.jobs})</div>
              </div>
            </IconButton>
          )}
        </div>

        {children}
      </div>
      <div className="w-1/3 surface-box">{building.description}</div>
    </div>
  )
}

export default MenuBuilding