import { hour } from "../../data/_data";
import { IconHammer } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Tool } from "../../types/types";
import { resourceWood } from "../resources/basic";
import { resourceCopperIngot, resourceIronIngot } from "../resources/ingots";

// Wooden Hammer
export const toolWoodenHammer: Tool = {
  id: createId(),
  name: "Wooden Hammer",
  rarity: "common",
  materialType: "woodenHammer",
  icon: IconHammer,
  efficiency: 1.0,
  type: "hammer",
  craftingCost: [
    { resource: resourceWood, amount: 3 },
  ],
  tradeValue: 5,
  status: { max: 100, current: 100 },
  time: hour * 2,
};

// Copper Hammer
export const toolCopperHammer: Tool = {
  id: createId(),
  name: "Copper Hammer",
  rarity: "rare",
  materialType: "copperHammer",
  icon: IconHammer,
  efficiency: 1.5,
  type: "hammer",
  craftingCost: [
    { resource: resourceCopperIngot, amount: 2 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 8,
  status: { max: 100, current: 100 },
  time: 10000,
};

// Iron Hammer
export const toolIronHammer: Tool = {
  id: createId(),
  name: "Iron Hammer",
  rarity: "epic",
  materialType: "ironHammer",
  icon: IconHammer,
  efficiency: 2.0,
  type: "hammer",
  craftingCost: [
    { resource: resourceIronIngot, amount: 3 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 12,
  status: { max: 100, current: 100 },
  time: 10000,
};

// Export const for referencing all hammers
export const toolsHammers: Tool[] = [toolWoodenHammer, toolCopperHammer, toolIronHammer];
