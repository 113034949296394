import { ReactNode } from "react"

const ProgressBar = ({
  current,
  max,
  icon,
  size = "medium",
  type,
}: {
  current: number
  max: number
  icon?: ReactNode
  size?: "small" | "medium" | "big"
  type?: "progress" | "health"
}) => {
  return (
    <div className="flex items-center space-x-2 w-full">
      {icon}
      <div className={`flex w-full bg-primary-dark overflow-hidden rounded-sm ${size === "small" ? "h-1" : size === "big" ? "h-6" : "h-4"}`}>
        <div className={`h-full transition-all rounded-sm ${type === "health" ? "bg-red-600" : "bg-primary"}`} style={{ width: ((current / max) * 100) + "%" }} />
      </div>
    </div>
  )
}

export default ProgressBar