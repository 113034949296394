import { week } from "../../data/_data";
import { IconBread, IconCheese, IconApple, IconCarrot, IconOnion, IconMilk, IconFish, IconFriedFish, IconMushrooms } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { CookedFood, Food, Ingredient, Resource } from "../../types/types"

// Bread
export const resourceBread: Food = {
  id: createId(),
  type: "bread",
  name: "Bread",
  rarity: "common",

  icon: IconBread,
  byproduct: undefined,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 30,
};

// Cheese
export const resourceCheese: Food = {
  id: createId(),
  name: "Cheese",
  type: "cheese",
  rarity: "common",

  icon: IconCheese,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 30,
};

// Apple
export const resourceApple: Food = {
  id: createId(),
  name: "Apple",
  type: "apple",
  rarity: "common",

  icon: IconApple,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 5,
};

// Carrot
export const resourceCarrot: Food = {
  id: createId(),
  name: "Carrot",
  type: "carrot",
  rarity: "common",

  icon: IconCarrot,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 30,
};

// Onion
export const resourceOnion: Food = {
  id: createId(),
  name: "Onion",
  type: "onion",
  rarity: "common",

  icon: IconOnion,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 30,
};

// Milk
export const resourceMilk: Food = {
  id: createId(),
  name: "Milk",
  type: "milk",
  rarity: "common",

  icon: IconMilk,
  category: "food",

  tradeValue: 1,
  status: { max: 100, current: 100 },
  satisfaction: 10,
  reduceHunger: 30,
};
// Mushroom
export const resourceMushroom: Ingredient = {
  id: createId(),
  name: "Mushroom",
  type: "mushroom",
  rarity: "common",

  icon: IconMushrooms,
  category: "food",

  tradeValue: 1,
  status: { max: week, current: week },
};


// Fish
export const resourceFish: Ingredient = {
  id: createId(),
  name: "Fish",
  type: "fish",
  rarity: "common",

  icon: IconFish,
  category: "food",

  tradeValue: 1,
  status: { max: week, current: week },
};
// Fried Fish
export const resourceFriedFish: CookedFood = {
  id: createId(),
  name: "Fried Fish",
  type: "friedFish",
  rarity: "common",

  icon: IconFriedFish,
  category: "cookedFood",

  tradeValue: 2,
  status: { max: week * 2, current: week * 2 },
  time: { current: 0, max: 10 },
  craftingCost: [{ resource: resourceFish, amount: 1 }],
  satisfaction: 10,
  reduceHunger: 30,
};

// Mushroom Soup
export const resourceMushroomSoup: CookedFood = {
  id: createId(),
  name: "Mushroom Soup",
  type: "mushroomSoup",
  rarity: "common",

  icon: IconMushrooms,
  category: "cookedFood",

  tradeValue: 2,
  status: { max: week * 2, current: week * 2 },
  time: { current: 0, max: 10 },
  craftingCost: [{ resource: resourceMushroom, amount: 5 }],
  satisfaction: 10,
  reduceHunger: 30,
};

// Export const for referencing all Food resources
export const foodResources: Resource[] = [
  resourceBread,
  resourceCheese,
  resourceApple,
  resourceCarrot,
  resourceOnion,
  resourceMilk,
  resourceFish,
];


