import { IconCopperIngot, IconGoldIngot, IconIronIngot, IconSilverIngot } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types";

// Iron Ingot
export const resourceIronIngot: Resource = { 
  id: createId(),
  name: "Iron Ingot",
  type: "ironIngot",
  rarity: "common",
  
  icon: IconIronIngot,
  category: "ingot",
  
  tradeValue: 1,
};

// Copper Ingot
export const resourceCopperIngot: Resource = {
  id: createId(),
  name: "Copper Ingot",
  type: "copperIngot",
  rarity: "common",
  
  icon: IconCopperIngot,
  category: "ingot",
  
  tradeValue: 1,
};

// Silver Ingot
export const resourceSilverIngot: Resource = {
  id: createId(),
  name: "Silver Ingot",
  type: "silverIngot",
  rarity: "common",
  
  icon: IconSilverIngot,
  category: "ingot",
  
  tradeValue: 1,
};

// Gold Ingot
export const resourceGoldIngot: Resource = {
  id: createId(),
  name: "Gold Ingot",
  
  type: "goldIngot",
  rarity: "common",
  icon: IconGoldIngot,
  category: "ingot",
  
  tradeValue: 1,
};

// Export const for referencing all Ingot resources
export const ingotResources: Resource[] = [resourceIronIngot, resourceCopperIngot, resourceSilverIngot, resourceGoldIngot];

