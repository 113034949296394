import { hour } from "../../data/_data";
import { IconBackpack } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Tool } from "../../types/types";
import { resourceWood } from "../resources/basic";

// Backpack
export const toolBackpack: Tool = {
  id: createId(),
  name: "Backpack",
  rarity: "common",
  materialType: "leatherBackpack",
  icon: IconBackpack,
  efficiency: 1,
  type: "backpack",
  craftingCost: [
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 9,
  status: { max: 100, current: 100 },
  time: hour * 8,
};