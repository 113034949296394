import { hour } from "../../data/_data";
import { IconKitchenUtensils } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Tool } from "../../types/types";
import { resourceWood } from "../resources/basic";

// Wooden Kitchen Utensils
export const toolWoodenKitchenUtensils: Tool = {
  id: createId(),
  name: "Wooden Kitchen Utensils",
  rarity: "common",
  materialType: "woodenKitchenUtensils",
  icon: IconKitchenUtensils,
  efficiency: 1,
  type: "kitchenUtensils",
  craftingCost: [
    { resource: resourceWood, amount: 2 },
  ],
  tradeValue: 5,
  status: { max: 100, current: 100 },
  time: hour * 4,
};

// Export const for referencing all hoes
export const toolsHoes: Tool[] = [toolWoodenKitchenUtensils];
