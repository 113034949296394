import { BuildingHome } from "../../types/types"
import MenuBuilding, { MenuBuildingProps } from "./MenuBuilding"


interface MenuBuildingHomeProps extends MenuBuildingProps {
  building: BuildingHome
}

const MenuBuildingHome = ({
  building,
  data,
  setData,
}: MenuBuildingHomeProps) => {

  return (
    <MenuBuilding
      building={building}
      data={data}
      setData={setData}
    >
    </MenuBuilding >
  )
}

export default MenuBuildingHome