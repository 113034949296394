
import { trees } from "../components/Tree";
import { resourceClay, resourceStone, resourceWood } from "../items/resources/basic";
import { resourceApple, resourceFish, resourceFriedFish, resourceMushroom, resourceMushroomSoup } from "../items/resources/food";
import { toolCopperAxe } from "../items/tools/axe";
import { toolWoodenFishingRod } from "../items/tools/fishingrod";
import { toolWoodenHammer } from "../items/tools/hammer";
import { toolCopperPickaxe } from "../items/tools/pickaxe";
import { BuildingFisher, BuildingHerbalist, BuildingHome, BuildingKitchen, BuildingLumbercamp, BuildingProduction, BuildingStorage, BuildingWorkshop, CityBuilding, TavernBuilding } from "../types/types";
import { hour } from "./_data";
import { IconBook, IconChest, IconClaypit, IconFishingRod, IconHerb, IconKitchen, IconLumbercamp, IconQuarry, IconTent } from "./icons";
import ImageTavern from "../assets/city/tavern.png"
import ImageMarket from "../assets/city/market.png"
import { createWorker } from "./player";

export const buildingQuarry: BuildingProduction = {
  id: "quarry",
  name: "Quarry",
  description: "Harvests stone from the quarry. Key resource for construction.",
  icon: IconQuarry,
  workers: [],
  resourceProduced: [{
    resource: resourceStone,
    quantity: { current: 500, max: 500 },
    regeneration: { current: 0, rate: 0.1 },
    progress: { current: 0, needed: hour / 2 },
  },],
  level: 0,
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 500, y: 300 },
    jobs: 2,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 500, y: 300 },
    cost: [
      { resource: resourceWood, amount: 2, },
      { resource: resourceStone, amount: 2, },
    ],
    jobs: 0.3,
  },],
}

export const buildingLumbercamp: BuildingLumbercamp = {
  id: "lumbercamp",
  name: "Lumbercamp",
  description: "Harvests wood from the forest. Key resource for construction and crafting.",
  icon: IconLumbercamp,
  workers: [],
  resourceProduced: [{
    resource: resourceWood,
    quantity: { current: 500, max: 500 },
    regeneration: { current: 0, rate: 0.005 },
    progress: { current: 0, needed: hour / 2 },
  }, {
    resource: resourceApple,
    quantity: { current: 100, max: 200 },
    regeneration: { current: 0, rate: 0.001 },
    progress: { current: 0, needed: hour / 2 },
  },],
  level: 0,
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 1645, y: 2837 },
    jobs: 2,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 1645, y: 2652 },
    cost: [{ resource: resourceWood, amount: 2, }],
    jobs: 3,
  }, {
    level: 2,
    efficiency: 0.3,
    coords: { x: 1645, y: 2652 },
    cost: [{ resource: resourceWood, amount: 2, }],
    jobs: 3,
  },],
  trees: trees,
}

export const buildingStorage: BuildingStorage = {
  id: "storage",
  name: "Storage",
  description: "Stores and organizes all items. \n Workers go out and get resources from production buildings to make them available in the Storage",
  icon: IconChest,
  workers: [],
  level: 0,
  levels: [{
    level: 0,
    efficiency: 1,
    coords: { x: 3050, y: 950 },
    jobs: 0,
  }, {
    level: 1,
    efficiency: 2,
    coords: { x: 2920, y: 790 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 0,
  }, {
    level: 1,
    efficiency: 2,
    coords: { x: 2920, y: 760 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 0,
  },],
  storage: 50,
}

export const buildingHome: BuildingHome = {
  id: "home",
  name: "Home",
  description: "Your home.",
  icon: IconTent,
  level: 0,
  workers: [],
  levels: [{
    level: 0,
    efficiency: 1,
    coords: { x: 2630, y: 750 },
    jobs: 0,
  }, {
    level: 1,
    efficiency: 2,
    coords: { x: 2630, y: 750 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 0,
  }, {
    level: 2,
    efficiency: 3,
    coords: { x: 2630, y: 750 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 0,
  }, {
    level: 3,
    efficiency: 4,
    coords: { x: 2630, y: 750 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 0,
  },],
}

export const buildingKitchen: BuildingKitchen = {
  id: "kitchen",
  name: "Kitchen",
  description: "Your kitchen. Prepare food for worker-satisfaction and health",
  icon: IconKitchen,
  level: 0,
  workers: [],
  recipes: [resourceFriedFish, resourceMushroomSoup,],
  selectedRecipes: [],
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 2200, y: 770 },
    jobs: 2,
  },],
}

export const buildingHerbalist: BuildingHerbalist = {
  id: "herbalist",
  name: "Herbalist",
  description: "The Herbalist collects plants in the forest and prepares healing items.",
  icon: IconHerb,
  resourceProduced: [{
    resource: resourceApple,
    quantity: { current: 100, max: 100 },
    regeneration: { current: 0, rate: 0.01 },
    progress: { current: 0, needed: hour * 2 },
  }, {
    resource: resourceMushroom,
    quantity: { current: 100, max: 100 },
    regeneration: { current: 0, rate: 0.1 },
    progress: { current: 0, needed: hour / 4 },
  },],
  level: 0,
  workers: [],
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 4600, y: 2400 },
    jobs: 1,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 4748, y: 2296 },
    jobs: 1,
  }, {
    level: 2,
    efficiency: 0.3,
    coords: { x: 5204, y: 2297 },
    jobs: 1,
  },],
}

export const buildingClay: BuildingProduction = {
  id: "claypit",
  name: "Claypit",
  description: "Workers here harvest clay from the claypit. Key resource for construction.",
  icon: IconClaypit,
  level: 0,
  workers: [],
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 5190, y: 1162 },
    jobs: 1,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 4862, y: 1162 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 2,
  }, {
    level: 2,
    efficiency: 0.3,
    coords: { x: 4862, y: 1162 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 4,
  },],
  resourceProduced: [{
    resource: resourceClay,
    quantity: { current: 500, max: 500 },
    regeneration: { current: 0, rate: 0.1 },
    progress: { current: 0, needed: hour / 2 },

  }],
}

export const buildingFisher: BuildingFisher = {
  id: "fisher",
  name: "Fisher",
  description: "Workers here go fishing to provide you with a reliable source of food. Fish has to be prepared in the kitchen before consumption",
  icon: IconFishingRod,
  level: 0,
  workers: [],
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 4156, y: 769 },
    jobs: 1,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 4156, y: 636 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 2,
  },],
  resourceProduced: [{
    resource: resourceFish,
    quantity: { current: 500, max: 500 },
    regeneration: { current: 0, rate: 0.1 },
    progress: { current: 0, needed: hour / 2 },
  }],
}

export const buildingWorkshop: BuildingWorkshop = {
  id: "workshop",
  name: "Workshop",
  description: "Workers here craft tools and research new technologies so your settlement can grow.",
  icon: IconBook,
  workers: [],
  level: 0,
  levels: [{
    level: 0,
    efficiency: 0.1,
    coords: { x: 3380, y: 600 },
    jobs: 1,
  }, {
    level: 1,
    efficiency: 0.2,
    coords: { x: 3380, y: 600 },
    cost: [{ resource: resourceWood, amount: 2 }],
    jobs: 1,
  },],
  recipes: [toolCopperAxe, toolCopperPickaxe, toolWoodenFishingRod, toolWoodenHammer],
  selectedRecipe: undefined,
  toolToProduce: undefined
}

export const cityBuildingTavern: TavernBuilding = {
  id: "tavern",
  name: "Tavern",
  image: ImageTavern,
  coords: { x: 596, y: 65 },
  patrons: [
    createWorker(),
    createWorker(),
    createWorker(),
    createWorker(),
    createWorker(),
    createWorker(),
    createWorker(),
    createWorker(),
  ],
  lastVisited: "Late Morning"
}

export const cityBuildingMarket: CityBuilding = {
  id: "market",
  name: "Market",
  image: ImageMarket,
  coords: { x: 767, y: 925 },
}