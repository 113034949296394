import { BuildingLumbercamp, forestData } from "../types/types";
import { calculateVisibleTreeCount } from "./Tree";

const Forest = ({ lumbercamp }: { lumbercamp: BuildingLumbercamp }) => {
  const trees = lumbercamp.trees
  const maxVisibleTrees = calculateVisibleTreeCount(lumbercamp.resourceProduced[0].quantity.current, forestData.treeAmount);
  const visibleTrees = trees.slice(0, maxVisibleTrees);

  return (
    <div className="forest absolute pointer-events-none" style={{ width: forestData.width, height: forestData.height, left: forestData.x, top: forestData.y }}>
      {visibleTrees.map((tree, index) => (
        <img key={index} src={tree.tree} alt={`Tree ${index}`} className=" transition-all duration-500" style={{ position: 'absolute', top: tree.position.y, left: tree.position.x }} />
      ))}
    </div>
  );
};

export default Forest;
