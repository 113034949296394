import { hour } from "../../data/_data";
import { IconAxe } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Tool } from "../../types/types";
import { resourceWood } from "../resources/basic";
import { resourceIronIngot } from "../resources/ingots";

// Copper Axe
export const toolCopperAxe: Tool = {
  id: createId(),
  name: "Copper Axe",
  rarity: "rare",
  materialType: "copperAxe",
  icon: IconAxe,
  efficiency: 1.8,
  type: "axe",
  craftingCost: [
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 9,
  status: { max: 100, current: 100 },
  time: hour * 8,
};

// Iron Axe
export const toolIronAxe: Tool = {
  id: createId(),
  name: "Iron Axe",
  rarity: "epic",
  materialType: "ironAxe",
  icon: IconAxe,
  efficiency: 2.0,
  type: "axe",
  craftingCost: [
    { resource: resourceIronIngot, amount: 3 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 12,
  status: { max: 100, current: 100 },
  time: hour * 16,
};

// Export const for referencing all axes
export const toolsAxes: Tool[] = [toolCopperAxe, toolIronAxe];
