import { hour } from "../data/_data";
import { IconCoin, IconHourglass } from "../data/icons";
import { getWorkerName, toLetterCase } from "../helper/stringHelper";
import { Tool, Worker } from "../types/types";
import { Icon, IconTile } from "./Icon";

export const ToolDataTable = ({
  tool,
  craftingInfo,
}: {
  tool: Tool
  craftingInfo?: boolean
}) => (
  <table>
    <tbody>
      <tr>
        <td colSpan={2} className={`text-${tool.rarity}`}>{tool.name}</td>
      </tr>
      <tr>
        <td>Efficiency</td>
        <td>{tool.efficiency}</td>
      </tr>
      <tr>
        <td>Value</td>
        <td><div className="flex items-center gap-1"><Icon size="small" icon={IconCoin} />{tool.tradeValue}</div></td>
      </tr>
      {!craftingInfo && (<tr>
        <td>Status</td>
        <td>{tool.status.current}/{tool.status.max}</td>
      </tr>)}
      {craftingInfo && (
        <>
          <tr>
            <td>
              {tool.craftingCost?.map(cost => (
                <div className="flex items-center gap-1"><Icon icon={cost.resource.icon} size="small" /> {cost.amount}</div>
              ))}
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex gap-1 items-center">
                <Icon size="small" icon={IconHourglass} />{tool.time / hour} hours
              </div>
            </td>
          </tr>
        </>
      )}
    </tbody>
  </table>
)

export const WorkerDataTable = ({ worker }: { worker: Worker }) => (
  <>
    <table className="text-xs w-full">
      <tbody>
        <tr><td colSpan={2} className="text-primary pb-2">{getWorkerName(worker)}</td></tr>
        <tr><td className="pr-2 text-left">Health</td><td className="text-right">{Math.floor(worker.health.current)} / {worker.health.max}</td></tr>
        <tr><td className="pr-2 text-left">Happiness</td><td className="text-right">{Math.floor(worker.happiness.current)} / {worker.happiness.max}</td></tr>
        <tr><td className="pr-2 text-left">Strength</td><td className="text-right">{Math.floor(worker.strength)}</td></tr>
        <tr><td className="pr-2 text-left">Stamina</td><td className="text-right">{Math.floor(worker.stamina.current)} / {worker.stamina.max}</td></tr>
        <tr><td className="pr-2 text-left">Hunger</td><td className="text-right">{Math.floor(worker.hunger.current)} / {worker.hunger.max}</td></tr>
        <tr><td className="pr-2 text-left">Thirst</td><td className="text-right">{Math.floor(worker.thirst.current)} / {worker.thirst.max}</td></tr>
        <tr><td colSpan={2} className="text-primary pt-2" >Perks</td></tr>
        {Object.entries(worker.perks).map((perk, i) => (
          <tr key={i} className=" even:bg-white even:bg-opacity-10"><td className="pr-2 text-left">{toLetterCase(perk[0])}</td><td className="text-right">{perk[1].level}</td></tr>
        ))}
      </tbody>
    </table>
    {Object.entries(worker.inventory.tools).length > 0 && <div className="flex flex-wrap gap-2 mt-2">
      {Object.entries(worker.inventory.tools).map((tool, i) => {
        return tool[1] && <div className="flex gap-2" key={i}><IconTile item={tool[1]} size="small" ><ToolDataTable tool={tool[1]} /></IconTile></div>
      })}
    </div>}
  </>
)