import tree1 from "../assets/trees/tree_01.png";
import tree2 from "../assets/trees/tree_02.png";
import tree3 from "../assets/trees/tree_03.png";
import tree4 from "../assets/trees/tree_04.png";
import tree5 from "../assets/trees/tree_05.png";
import tree6 from "../assets/trees/tree_06.png";
import tree7 from "../assets/trees/tree_07.png";
import tree8 from "../assets/trees/tree_08.png";
import tree9 from "../assets/trees/tree_09.png";
import { getRandomNumber } from '../helper/mathHelper';
import { TreeInterface, TreePosition, forestData } from '../types/types';

export const treeAssets = [tree1, tree2, tree3, tree4, tree5, tree6, tree7, tree8, tree9,];
const isPositionInExcludedArea = (position: TreePosition): boolean => {
  // Define the excluded area (for example, a rectangle in the center)
  const excludedArea = forestData.excludedArea

  // Check if the position is inside the excluded area
  return (
    position.x >= excludedArea.x &&
    position.x <= excludedArea.x + excludedArea.width &&
    position.y >= excludedArea.y &&
    position.y <= excludedArea.y + excludedArea.height
  );
};

const generateRandomTreePosition = (): TreePosition => {
  let position: TreePosition;

  do {
    // Generate a random position
    position = {
      x: getRandomNumber(0, forestData.width),
      y: getRandomNumber(0, forestData.height),
    };
  } while (isPositionInExcludedArea(position));

  return position;
};

export const trees: TreeInterface[] = [...Array(forestData.treeAmount)].map(() => ({
  tree: treeAssets[getRandomNumber(1, treeAssets.length - 1)],
  position: generateRandomTreePosition(),
}));

export const calculateVisibleTreeCount = (availableQuantity: number, maxTreeAmount: number) => {
  const visibleTreePercentage = availableQuantity / maxTreeAmount;
  return Math.floor(forestData.treeAmount * visibleTreePercentage);
};
