import { getResourceId } from "../../helper/dataHelper";
import { BuildingWorkshop, Cost, Tech, Tool } from "../../types/types";
import { IconButton } from "../Button";
import { ToolDataTable } from "../DataTable";
import MenuBuilding, { MenuBuildingProps } from "./MenuBuilding";

interface MenuBuildingWorkshopProps extends MenuBuildingProps {
  building: BuildingWorkshop
}

const MenuBuildingWorkshop = ({
  building,
  data,
  setData,
}: MenuBuildingWorkshopProps) => {

  const handleRecipeSelection = (tool: Tool) => {
    const d = { ...data }
    d.buildings.workshop.selectedRecipe === tool ? d.buildings.workshop.selectedRecipe = undefined : d.buildings.workshop.selectedRecipe = tool
    d.buildings.workshop.toolToProduce = undefined
    setData(d)
  };

  const handleResearchSelection = (tech: Tech) => {
    const d = { ...data }
    d.buildings.workshop.selectedResearch === tech ? d.buildings.workshop.selectedResearch = undefined : d.buildings.workshop.selectedResearch = tech
    d.buildings.workshop.techToResearch = undefined
    setData(d)
  }

  return (
    <MenuBuilding
      building={building}
      data={data}
      setData={setData}
    >
      <div className="technologies">
        <div className="mb-1">Technologies</div>
        <div className="surface-box gap-2 flex flex-wrap">
          {Object.entries(data.tech).map(([key, tech], i) => (
            <IconButton
              key={i}
              icon={tech.icon}
              progress={{ current: tech.progress.current, max: tech.progress.max }}
              active={data.buildings.workshop.selectedResearch?.id === tech.id}
              onClick={() => handleResearchSelection(tech)}
              disabled={data.tech[tech.id].active}
            >
              <div className="flex flex-col gap-2 text-sm">
                <div className="">{tech.description}</div>
                {tech.active && <div className="text-primary">Learned</div>}
                <table className="">
                  <tbody>
                    {tech.cost.map((cost: Cost, i) => (
                      <tr key={i}><td className='pr-2'>{cost.resource.name}</td><td className='w-full'>{data.resources[getResourceId(cost.resource)].length}/{cost.amount}</td></tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </IconButton>
          ))}
        </div>
      </div>

      <div className="recipes">
        <div className="mb-1">Recipes</div>
        <div className="surface-box gap-2 flex flex-wrap">
          {building.recipes?.map((recipe, i) => {
            return (
              <IconButton
                key={i}
                icon={recipe.icon}
                onClick={() => handleRecipeSelection(recipe)}
                active={data.buildings.workshop.selectedRecipe?.id === recipe.id}
                progress={data.buildings.workshop.selectedRecipe === recipe ? { current: data.buildings.workshop.toolToProduce?.time || 0, max: data.buildings.workshop.selectedRecipe?.time || 0 } : undefined}
                color={recipe.rarity}
              >
                <ToolDataTable tool={recipe} craftingInfo />
              </IconButton>
            )
          })}
        </div>
      </div>
    </MenuBuilding>
  )
}

export default MenuBuildingWorkshop