import { Data } from "../types/types";

export const runStorage = async (
  data: Data,
  setData: React.Dispatch<React.SetStateAction<Data>>
) => {
  /* const d = { ...data };
  let totalManpower = 0; */

  // Calculate total manpower based on workers' storage perks
 /*  d.buildings.storage.workers.forEach(worker => {
    totalManpower += worker.perks.storage.level;
  });

  // Iterate through production buildings
  Object.entries(d.buildings).forEach(([key, building]) => {
    if (isProductionBuilding(building)) {
      const productionBuilding = building as BuildingProduction;

      // Check if the production building has storage and resources
      if (productionBuilding.storage && productionBuilding.storage.resources.length > 0) {
        const resourcesToMove = productionBuilding.storage.resources.slice(0, totalManpower);

        // Calculate available space in main storage for each resource
        resourcesToMove.forEach(resource => {
          const resourceId = getResourceId(resource);
          const mainStorageSpace = getMaxStorage(d.buildings.storage) - d.resources[resourceId].length;

          // Move resources to storage if there's enough space
          if (mainStorageSpace > 0) {
            const resourceIndex = productionBuilding.storage.resources.findIndex(r => r.id === resource.id);
            if (resourceIndex !== -1) {
              const movedResource = productionBuilding.storage.resources.splice(resourceIndex, 1)[0];
              d.resources[resourceId].push(movedResource);
            }
          } else {
            console.log(`Not enough space in main storage to move resources of type ${resource.type}`);
          }
        });
      }
    }
  });

  setData(d); */
};
