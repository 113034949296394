import { IconBrick, IconGlass, IconLeather, IconSparkle, IconLog } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types"

// Brick
export const resourceBrick: Resource = {
  id: createId(),
  name: "Brick",
  type: "brick",
  rarity: "common",
  icon: IconBrick,
  
  category: "processed",
  
  tradeValue: 1,
};

// Glass
export const resourceGlass: Resource = {
  id: createId(),
  name: "Glass",
  type: "glass",
  rarity: "common",
  icon: IconGlass,
  
  category: "processed",
  
  tradeValue: 1,
};

// Leather
export const resourceLeather: Resource = {
  id: createId(),
  name: "Leather",
  type: "leather",
  rarity: "common",
  icon: IconLeather,
  category: "processed",
  
  
  tradeValue: 1,
};

// Wooden Nail
export const resourceWoodenNail: Resource = {
  id: createId(),
  name: "Wooden Nail",
  type: "woodenNail",
  rarity: "common",
  icon: IconSparkle,
  
  category: "processed",
  
  tradeValue: 1,
};

// Copper Nails
export const resourceCopperNail: Resource = {
  id: createId(),
  name: "Copper Nails",
  type: "copperNail",
  rarity: "common",
  icon: IconSparkle,
  
  category: "processed",
  
  tradeValue: 1,
};

// Iron Nails
export const resourceIronNail: Resource = {
  id: createId(),
  name: "Iron Nails",
  type: "ironNail",
  rarity: "common",
  icon: IconSparkle,
  category: "processed",
  
  
  tradeValue: 1,
};

// Log
export const resourceLog: Resource = {
  id: createId(),
  name: "Log",
  type: "log",
  rarity: "common",
  
  icon: IconLog,
  category: "processed",
  
  tradeValue: 1,
};

// Export const for referencing all Processed Material resources
export const processedMaterialResources: Resource[] = [
  resourceBrick,
  resourceGlass,
  resourceLeather,
  resourceCopperNail,
  resourceIronNail,
  resourceLog,
  resourceWoodenNail,
];

