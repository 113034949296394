import { IconIronOre, IconCopperOre, IconSilverOre, IconGoldOre } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types"

// Iron Ore
export const resourceIronOre: Resource = {
  id: createId(),
  name: "Iron Ore",
  
  type: "ironOre",
  rarity: "common",
  icon: IconIronOre,
  category: "ore",
  
  tradeValue: 1,
};

// Copper Ore
export const resourceCopperOre: Resource = {
  id: createId(),
  name: "Copper Ore",
  
  type: "copperOre",
  rarity: "common",
  icon: IconCopperOre,
  category: "ore",
  
  tradeValue: 1,
};

// Silver Ore
export const resourceSilverOre: Resource = {
  id: createId(),
  name: "Silver Ore",
  
  type: "silverOre",
  rarity: "common",
  icon: IconSilverOre,
  category: "ore",
  
  tradeValue: 1,
};

// Gold Ore
export const resourceGoldOre: Resource = {
  id: createId(),
  name: "Gold Ore",
  
  type: "goldOre",
  rarity: "common",
  icon: IconGoldOre,
  category: "ore",
  
  tradeValue: 1,
};

// Export const for referencing all Ore resources
export const oreResources: Resource[] = [resourceIronOre, resourceCopperOre, resourceSilverOre, resourceGoldOre];
