import { ReactNode } from "react";

const ConditionalWrapper = ({
  condition,
  wrapper, 
  children
}: {
  condition: boolean,
  wrapper: any,
  children: ReactNode
}) =>
  condition ? wrapper(children) : children

export default ConditionalWrapper