import { Dispatch, SetStateAction } from "react";
import { getRarity } from "../data/_data";
import { Building, Data, Food, ResourceType, Worker } from "../types/types";
import { getBuildingId, getToolForBuilding, isFood } from "./dataHelper";

export const manageHunger = (
  data: Data,
  setData: Dispatch<SetStateAction<Data>>
) => {
  const d = { ...data };

  // Iterate over workers
  d.workers.forEach(worker => {
    if (worker.id !== "player") {

      if (worker.hunger.current < worker.hunger.max) {
        let foodAvailable = true; // Assume food is available initially

        while (worker.hunger.current < worker.hunger.max && foodAvailable) {
          foodAvailable = false; // Set to false, will be set to true if suitable food is found

          // Prepare a list of available food types
          const availableFoodTypes = Object.entries(d.resources).filter(([resourceType, resourceArray]) => {
            const resource = resourceArray[0] as Food
            return resourceArray.length > 0 &&
              isFood(resource) &&
              resource.reduceHunger <= (worker.hunger.max - worker.hunger.current)
          })

          // Check if there's more than one type of food or if the only available food is not the last eaten
          if (availableFoodTypes.length > 1 ||
            (availableFoodTypes.length === 1 && availableFoodTypes[0][0] !== worker.lastFood)) {
            for (const [resourceType, resourceArray] of availableFoodTypes) {
              // Skip the last consumed food if other options are available
              if (resourceType === worker.lastFood && availableFoodTypes.length > 1) continue;

              const resource = resourceArray[0] as Food; // Assuming resourceArray contains objects of type Food
              // Consume one food item
              resourceArray.pop(); // Remove the consumed food item
              worker.hunger.current = Math.min(worker.hunger.current + resource.reduceHunger, worker.hunger.max);
              worker.happiness.current = Math.min(worker.happiness.current + resource.satisfaction, worker.happiness.max);
              worker.lastFood = resourceType as ResourceType; // Update lastFood with the newly consumed food type
              console.log(`${worker.name} consumed ${resourceType}, reducing hunger.`);
              foodAvailable = true; // Food was found and consumed, so food is available
              break; // Break the for-loop since food has been consumed
            }
          }

          if (!foodAvailable) {
            // console.log(`${worker.name} is hungry but no suitable food is available or all available food is the last consumed type.`);
          }
        }
      }

      // Decrease hunger
      if (worker.hunger.current > 0) {
        worker.hunger.current = Math.max(worker.hunger.current - 0.2, 0);
      } else {
        // If hunger is already 0, decrease health
        worker.health.current = Math.max(worker.health.current - 0.2, 0);
      }

      // Adjust happiness based on hunger level
      if (worker.hunger.current < worker.hunger.max / 2) {
        worker.happiness.current = Math.max(worker.happiness.current - 0.2, 0);

      } else {
        worker.happiness.current = Math.min(worker.happiness.current + 0.4, worker.happiness.max);
      }

      if (worker.happiness.current <= 0) {
        unemployWorker(worker, data, setData)
      } else setData(d);


      if (worker.health.current <= 0) {
        console.log("Worker's health is 0 or below. Removing worker.");
        killWorker(worker, data, setData)
      } else setData(d);
    }
  });
};

export const killWorker = async (
  worker: Worker,
  data: Data,
  setData: Dispatch<SetStateAction<Data>>
) => {
  setData(oldData => {

    const d = { ...oldData };
    unemployWorker(worker, data, setData)
    d.workers = d.workers.filter(w => w.id !== worker.id)
    d.unemployedWorkers = d.unemployedWorkers.filter(w => w.id !== worker.id)

    return { ...oldData, workers: d.workers, unemployedWorkers: d.unemployedWorkers }
  })
}

export const assignWorker = async (building: Building, data: Data, setData: Dispatch<SetStateAction<Data>>, worker?: Worker) => {
  if (worker) {
    const d = { ...data };
    const buildingId = getBuildingId(building)
    const dataBuilding = d.buildings[buildingId];
    const hasFreeJobs = dataBuilding.levels[building.level].jobs > dataBuilding.workers?.length;

    const tool = getToolForBuilding(building)
    const toolAvailable = data.toolItems.some(t => t.type === tool);
    const equippedTool = worker.inventory.tools[tool];

    if (d.unemployedWorkers.some(w => w.id === worker.id) && !dataBuilding.workers?.some(w => w.id === worker.id) && hasFreeJobs && (toolAvailable || equippedTool)) {
      const buildingWorkers = d.buildings[getBuildingId(building)].workers;

      if (!buildingWorkers.some(w => w.id === worker.id)) {
        d.unemployedWorkers = d.unemployedWorkers.filter((w: Worker) => w.id !== worker.id);
        buildingWorkers.push(worker);
        //TAKE TOOL
        if (buildingId) {
          const availableTools = data.toolItems.filter(t => t.type === tool);

          if (availableTools.length > 0 && !worker.inventory.tools[tool]) {
            // Sort tools based on rarity level
            const sortedTools = availableTools.sort((a, b) => getRarity(a.rarity) - getRarity(b.rarity));
            const bestTool = sortedTools[0]; // The tool with the lowest numeric rarity value is considered the best

            worker.inventory.tools[tool] = bestTool;
            d.toolItems = d.toolItems.filter(t => t.id !== bestTool.id);
          }
        }
        setData(d);
      }
    }
  }
};

export const unemployWorker = async (
  worker: Worker,
  data: Data,
  setData: Dispatch<SetStateAction<Data>>
) => {
  const d = { ...data };

  Object.entries(d.buildings).forEach(([key, building]) => {
    if (building.workers.some((w) => w.id === worker.id) && !d.unemployedWorkers.some((w) => w.id === worker.id)) {
      building.workers = d.buildings[getBuildingId(building)].workers.filter((w: Worker) => w.id !== worker.id);
      worker.workProgress = [];
      d.unemployedWorkers.push(worker);


      const toolId = getToolForBuilding(building);
      const tool = worker.inventory.tools[toolId];

      if (tool) {
        worker.inventory.tools[toolId] = undefined;
        d.toolItems.push(tool);
      }

      setData(d);
    }
  });
};

export const restWorker = (worker: Worker, data: Data, setData: Dispatch<SetStateAction<Data>>) => {
  const d = { ...data }
  if (worker.stamina.current < worker.stamina.max) {
    worker.stamina.current = Math.min(worker.stamina.current + (1 * worker.strength), worker.stamina.max);
    if (worker.stamina.current > worker.stamina.max / 2) worker.resting = false
    setData(d)
  }
}

export const takeTool = async (worker: Worker, building: Building, data: Data, setData: Dispatch<SetStateAction<Data>>) => {
  const d = { ...data };
  const buildingId = getBuildingId(building);

  if (buildingId === "quarry") {
    const pickaxeAvailable = data.toolItems.some(t => t.type === "pickaxe");
    const availableCopperPickaxe = data.toolItems.find(t => t.materialType === "copperPickaxe");
    const availableIronPickaxe = data.toolItems.find(t => t.materialType === "ironPickaxe");
    const pickaxe = worker.inventory.tools.pickaxe;

    if (pickaxeAvailable && !pickaxe) {
      if (availableIronPickaxe) {
        worker.inventory.tools.pickaxe = availableIronPickaxe;
        d.toolItems = d.toolItems.filter(t => t.id !== availableIronPickaxe.id);
      } else if (availableCopperPickaxe) {
        worker.inventory.tools.pickaxe = availableCopperPickaxe;
        d.toolItems = d.toolItems.filter(t => t.id !== availableCopperPickaxe.id);
      }
    }
  }
  setData(d);
};

export const restWorkers = async (data: Data, setData: Dispatch<SetStateAction<Data>>) => {
  const d = { ...data }
  d.unemployedWorkers.map(worker => {
    if (worker.stamina.current < worker.stamina.max) return worker.stamina.current += 2
    else return worker.stamina.current = worker.stamina.max
  })
  setData(d)
}