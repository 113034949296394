import { getRandomNumber } from "../helper/mathHelper";
import { createId } from "../helper/stringHelper";
import { Enemy } from "../types/dungeon";
import { Player, Sex, Worker } from "../types/types";
import { getRandomLastName, getRandomName, getRandomNickname } from "./names";
import { getRandomFemalePortrait } from "./portraitsFemale";
import { getRandomMalePortrait, malePortraits } from "./portraitsMale";

export const createWorker = (
  sex: Sex = getRandomNumber(1, 2) === 1 ? "male" : "female"


): Worker => {
  const stamina = getRandomNumber(80, 120)
  const health = getRandomNumber(80, 120)
  const strength = getRandomNumber(1, 5)
  const happiness = getRandomNumber(80, 120)
  const hunger = getRandomNumber(80, 120)

  const profession = getRandomNumber(0, 7)
  const professionLevel = getRandomNumber(3, 10)

  return {
    id: createId(),
    name: getRandomName(sex),
    lastName: getRandomLastName(),
    nickname: getRandomNickname(),
    portrait: sex === "male" ? getRandomMalePortrait() : getRandomFemalePortrait(),
    sex: sex,
    workProgress: [0],
    resting: false,
    wage: Math.floor(professionLevel * 1.5),
    perks: {
      woodcutting: { level: profession === 0 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      mining: { level: profession === 1 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      storage: { level: profession === 2 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      extraction: { level: profession === 3 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      crafting: { level: profession === 4 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      fishing: { level: profession === 5 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      cooking: { level: profession === 6 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
      herbalist: { level: profession === 7 ? professionLevel : getRandomNumber(1, 2), exp: 0 },
    },
    health: { current: health, max: health },
    stamina: { current: stamina, max: stamina },
    happiness: { current: happiness, max: happiness },
    strength: strength,
    hunger: { current: hunger, max: hunger },
    lastFood: undefined,
    thirst: { current: 100, max: 100 },
    inventory: { tools: {}, },
  }
}

export const player: Player = {
  id: "player",
  name: undefined,
  lastName: undefined,
  nickname: undefined,
  portrait: malePortraits[0],
  sex: undefined,
  workProgress: [0],
  resting: false,
  perks: {
    woodcutting: { level: 1, exp: 0 },
    mining: { level: 1, exp: 0 },
    storage: { level: 1, exp: 0 },
    extraction: { level: 1, exp: 0 },
    crafting: { level: 1, exp: 0 },
    fishing: { level: 1, exp: 0 },
    cooking: { level: 1, exp: 0 },
    herbalist: { level: 1, exp: 0 },
  },
  health: { current: 100, max: 100 },
  stamina: { current: 500, max: 500 },
  strength: 20,
  hunger: { current: 100, max: 100 },
  lastFood: undefined,
  thirst: { current: 100, max: 100 },
  happiness: { current: 100, max: 100 },
  inventory: { tools: {}, },
}

export const createEnemy = (
  sex: Sex = "male"
): Enemy => {
  const stamina = getRandomNumber(80, 120)
  const health = getRandomNumber(80, 120)
  const strength = getRandomNumber(5, 10)
  return {
    id: createId(),
    name: getRandomName(sex),
    lastName: getRandomLastName(),
    nickname: getRandomNickname(),
    portrait: sex === "male" ? getRandomMalePortrait() : getRandomFemalePortrait(),
    sex: sex,
    health: { current: health, max: { base: health, } },
    stamina: { current: stamina, max: { base: stamina, } },
    strength: strength,
    inventory: {},
  }
}