import { useEffect } from "react"
import { IconCoin } from "../../data/icons"
import { createWorker } from "../../data/player"
import { Data } from "../../types/types"
import Button from "../Button"
import { WorkerDataTable } from "../DataTable"
import { Icon } from "../Icon"
import WorkerPortrait from "../WorkerPortrait"

const MenuTavern = ({
  data,
  setData,
}: {
  data: Data
  setData: React.Dispatch<React.SetStateAction<Data>>
}) => {

  const tavern = data.city.buildings.tavern
 
  useEffect(() => {
    if (tavern.lastVisited !== data.time.timeOfDay) {
      setData(od => ({
        ...od, city: {
          ...od.city, buildings: { 
            ...od.city.buildings, tavern: {
              ...od.city.buildings.tavern, patrons: (tavern.lastVisited !== data.time.timeOfDay) || od.city.buildings.tavern.patrons.length === 0 ? [
                createWorker(),
                createWorker(),
                createWorker(),
                createWorker(),
                createWorker(),
                createWorker(),
                createWorker(),
                createWorker(),
              ] : od.city.buildings.tavern.patrons,
              lastVisited: od.time.timeOfDay
            }
          }
        }
      }))
    }
    // eslint-disable-next-line
  }, [data.time.timeOfDay])

  return (
    <div className="grid grid-cols-4 gap-2 h-full overflow-y-auto scrollbar-thin scrollbar-track-primary">
      {tavern.patrons.map(worker => {
        return (
          <div key={worker.id} className="p-2 border border-primary rounded-sm flex justify-between flex-col items-center gap-2 text-center">
            <WorkerPortrait worker={worker} noInfo noTooltip />
            <WorkerDataTable worker={worker} />
            <Button><div className="flex items-center">Hire ({worker.wage}<Icon size="small" icon={IconCoin} />)</div></Button>
          </div>
        )
      })}
    </div>
  )
}

export default MenuTavern