import { ReactNode } from "react"
import { getEfficiency } from "../helper/dataHelper"
import { assignWorker, unemployWorker } from "../helper/workerHelper"
import { Data } from "../types/types"
import { Icon } from "./Icon"
import WorkerPortrait from "./WorkerPortrait"

export const MenuAssigments = ({
  data,
  setData
}: {
  data: Data
  setData: React.Dispatch<React.SetStateAction<Data>>
}) => {
  const d = { ...data }
  return (
    <table className="assignments-table">
      <thead>
        <tr className="">
          <th className="w-full text-left">Name</th>
          <th></th>
          <th></th>
          {Object.entries(d.buildings).map(([key, building]) => {
            const level = building.level
            const buildingLevel = building.levels[level]
            if (buildingLevel.jobs && buildingLevel.jobs > 0) {
              return (
                <th key={`th-${building.id}`}>
                  <Icon icon={building.icon} />
                </th>
              )
            } else return <></>
          })}
        </tr>
      </thead>

      <tbody>
        {d.workers.map(worker => {
          return (
            <tr key={worker.id}>
              <td className="w-full">
                <div className="flex items-center gap-2"><WorkerPortrait size="small" worker={worker} /> {worker.name} {worker.lastName}</div>
              </td>
              <td>
                {worker.wage}
              </td>
              <td className="text-center">
                <AssignmentBox active={d.unemployedWorkers.some(w => w.id === worker.id)} action={() => unemployWorker(worker, d, setData)} />
              </td>
              {Object.entries(d.buildings).map(([key, building]) => {
                const level = building.level
                const buildingLevel = building.levels[level]
                if (buildingLevel.jobs && buildingLevel.jobs > 0) {
                  const isAssigned = building.workers.some(w => w.id === worker.id)
                  return (
                    <td className="text-center" key={building.id}>
                      <AssignmentBox
                        children={getEfficiency(worker, building)}
                        active={isAssigned}
                        action={() => { unemployWorker(worker, d, setData); !isAssigned && assignWorker(building, d, setData, worker) }}
                      />
                    </td>
                  )
                } else return null
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const AssignmentBox = ({ children, active, action }: { children?: ReactNode; active: boolean; action: () => void }) => {
  return (
    <div
      className={`w-8 h-8 rounded-sm flex items-center justify-center border border-primary transition-colors duration-300 text-sm ${active ? "bg-primary text-gray-900" : "bg-transparent hover:bg-primary hover:text-gray-900"}`}
      onClick={action}
    >
      <span>{children}</span>
    </div>
  )
}