import { resourceHide, resourceManure, resourceWool } from "../items/resources/animal"
import { resourceClay, resourceStick, resourceStone, resourceWood } from "../items/resources/basic"
import { resourceCharcoal, resourceCoal, resourceOil, resourcePeat } from "../items/resources/energy"
import { resourceApple, resourceBread, resourceCarrot, resourceCheese, resourceFish, resourceFriedFish, resourceMilk, resourceMushroom, resourceMushroomSoup, resourceOnion } from "../items/resources/food"
import { resourceCopperIngot, resourceGoldIngot, resourceIronIngot, resourceSilverIngot } from "../items/resources/ingots"
import { resourceHealingPoultice, resourceHerb, resourceMedicinalTincture, resourceWillowBark } from "../items/resources/medicinal"
import { resourceCopperOre, resourceGoldOre, resourceIronOre, resourceSilverOre } from "../items/resources/ores"
import { resourceBrick, resourceCopperNail, resourceGlass, resourceIronNail, resourceLeather, resourceLog, resourceWoodenNail } from "../items/resources/processed"
import { resourceGemstone, resourceGoldCoin } from "../items/resources/treasury"
import { Building, BuildingProduction, BuildingStorage, BuildingType, Data, PerkType, Resource, ResourceCategory, ResourceType, ToolType, Worker } from "../types/types"

export const getBuildingId = (building: Building): keyof Data['buildings'] => building.id as BuildingType
export const getResourceId = (resource: Resource): keyof Data['resources'] => resource.type as ResourceType

export const getResourceCategory = (resource: Resource): ResourceCategory => resource.category as ResourceCategory

export const getFullName = (worker: Worker): string => `${worker.name} "${worker.nickname}" ${worker.lastName}`

export const getMaxStorage = (storage: BuildingStorage) => storage.storage * storage.levels[storage.level].efficiency

export const getResourceInfo = (resource: ResourceType): Resource => {
  const resourceMapping: Record<string, Resource> = {
    stick: resourceStick,
    wood: resourceWood,
    stone: resourceStone,
    clay: resourceClay,
    
    copperIngot: resourceCopperIngot,
    wool: resourceWool,
    hide: resourceHide,
    manure: resourceManure,

    coal: resourceCoal,
    peat: resourcePeat,
    charcoal: resourceCharcoal,
    oil: resourceOil,

    fish: resourceFish,
    mushroom: resourceMushroom,

    friedFish: resourceFriedFish,
    mushroomSoup: resourceMushroomSoup,
    bread: resourceBread,
    cheese: resourceCheese,
    apple: resourceApple,
    carrot: resourceCarrot,
    onion: resourceOnion,
    milk: resourceMilk,

    ironIngot: resourceIronIngot,
    silverIngot: resourceSilverIngot,
    goldIngot: resourceGoldIngot,

    herb: resourceHerb,
    healingPoultice: resourceHealingPoultice,
    medicinalTincture: resourceMedicinalTincture,
    willowBark: resourceWillowBark,

    ironOre: resourceIronOre,
    copperOre: resourceCopperOre,
    silverOre: resourceSilverOre,
    goldOre: resourceGoldOre,

    brick: resourceBrick,
    glass: resourceGlass,
    leather: resourceLeather,
    woodenNail: resourceWoodenNail,
    copperNail: resourceCopperNail,
    ironNail: resourceIronNail,
    log: resourceLog,
    goldCoin: resourceGoldCoin,
    gemstone: resourceGemstone,
  };

  return resourceMapping[resource] || null; // Return null if the resource type is not found
};

export const getToolForBuilding = (building: Building): ToolType => {
  const buildingToolMapping: Record<string, ToolType> = {
    quarry: "pickaxe",
    lumbercamp: "axe",
    claypit: "shovel",
    storage: "backpack",
    herbalist: "trowel",
    fisher: "fishingRod",
    workshop: "hammer",
    kitchen: "kitchenUtensils",
    // Add more building-to-tool mappings as needed
  };
  return buildingToolMapping[building.id];
}

export const getPerkForResource = (resource: Resource): PerkType | undefined => {
  const resourcePerkMapping: Record<string, PerkType> = {
    stone: "mining",
    wood: "woodcutting",
    clay: "extraction",
    storage: "storage",
    pottery: "crafting",
    fish: "fishing",
  };
  return resourcePerkMapping[resource.type];
};

export const getPerkForBuilding = (building: Building): PerkType | undefined => {
  const buildingPerkMapping: Record<string, PerkType> = {
    quarry: "mining",
    lumbercamp: "woodcutting",
    claypit: "extraction",
    storage: "storage",
    workshop: "crafting",
    fisher: "fishing",
    kitchen: "cooking",
    herbalist: "herbalist"
    // Add more building-to-perk mappings as needed
  };
  return buildingPerkMapping[building.id];
};

export const getToolEfficiency = (worker: Worker, building: Building): number => getToolForBuilding(building) ? worker.inventory.tools[getToolForBuilding(building)!]?.efficiency || 0 : 0

export const getEfficiency = (worker: Worker, building: Building): number => {
  //const baseEfficiency = worker.stamina.current / worker.stamina.max
  const skillBonus = getPerkForBuilding(building) ? worker.perks[getPerkForBuilding(building)!].level : 0
  return skillBonus;
};

export const getAssignedBuilding = (worker: Worker, data: Data): Building | BuildingProduction | undefined => {
  const buildings = Object.values(data.buildings);

  for (const building of buildings) {
    if (building.workers.find(w => w.id === worker.id)) {
      return building;
    }
  }

  return undefined;
};

export const isLocalhost = () => window.location.hostname === "localhost"

export const isFood = (resource: Resource) => {
  const type = resource.type
  return (
    type === "apple" ||
    type === "bread" ||
    type === "carrot" ||
    type === "cheese" ||
    type === "friedFish"
  )
}

export const isIngredient = (resource: Resource) => {
  const type = resource.type
  return (
    type === "carrot" ||
    type === "fish" ||
    type === "milk" ||
    type === "onion"
  )
}

export const isProductionBuilding = (building: Building) => {
  const id = building.id
  return (
    id === "lumbercamp" ||
    id === "claypit" ||
    id === "quarry" ||
    id === "fisher" ||
    id === "kitchen"
  )
}