import 'aos/dist/aos.css'
import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { ArrowLeft, X } from "react-feather"
import { Building, Data } from "../types/types"
import { Icon } from "./Icon"
import Night from "./Night"

interface ModalProps {
  children?: ReactNode
  data: Data
  title?: string | ReactNode
  icon?: string
  bg?: string
  hasLight?: boolean
  isOpen: boolean
  setIsOpen: (a: boolean) => void
}

const ModalContext = createContext(false)

const Modal = ({
  children,
  data,
  title,
  icon,
  bg = "",
  hasLight = false,
  isOpen = false,
  setIsOpen,
}: ModalProps) => {
  //const [isOpen, setIsOpen] = useState(false)
  const [isInsideModal, setIsInsideModal] = useState(false)

  const parentModalContext = useContext(ModalContext)

  useEffect(() => {
    setIsInsideModal(parentModalContext)
  }, [parentModalContext])

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") setIsOpen(false)
    })
    document.addEventListener('contextmenu', event => event.preventDefault());
    // eslint-disable-next-line
  }, [])
//  data-aos="fade" data-aos-duration="500"
  return (
    <ModalContext.Provider value={true}>
      {isOpen && document.getElementById("modals") && createPortal(
        <div className="modal fixed w-screen h-screen left-0 top-0 z-40">
          <div className="absolute top-0 left-0 bottom-0 right-0 w-full cursor-pointer" onClick={() => setIsOpen(false)} />
          <div className={`modal-content overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-full h-[500px] aspect-video bg-surface bg-cover p-4 z-50 flex flex-col`} style={{ backgroundImage: `url(${bg})` }} >
            <div className='absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50 -z-10' />
            <div className="flex justify-between items-center cursor-pointer bg-pattern-squares bg-surface p-1 mb-4">
              {title || icon ? <ModalTitle icon={icon}>{title}</ModalTitle> : <div />}
              <div onClick={() => setIsOpen(false)} className="text-primary">
                {isInsideModal ? <ArrowLeft size={24} /> : <X size={24} />}
              </div>
            </div>
            <div className="flex-1 w-full overflow-hidden">
              {children}
            </div>
            {(!data.tech.torches.active || (data.tech.torches.active && !data.lightsOn)) && !hasLight && <Night timeOfDay={data.time.timeOfDay} />}
          </div>
        </div>, document.getElementById("modals")!)}
    </ModalContext.Provider>
  )
}

export default Modal

export const BuildingTitle = ({ building }: { building: Building }) => (
  <ModalTitle icon={building.icon}>
    <span className="flex items-baseline gap-1"><span>{building.name}</span> <span className="text-xs ">(lvl. {building.level + 1})</span></span>
  </ModalTitle>
)

export const ModalTitle = ({
  children,
  icon,
}: {
  children: ReactNode
  icon?: string
}) => (
  <div className="flex items-center gap-2 text-xl">
    {icon && <Icon icon={icon} size="big" />}
    {children}
  </div>
)