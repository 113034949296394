import Dungeon0 from "../assets/backgrounds/locations/dungeon/0.jpg"
import Dungeon1 from "../assets/backgrounds/locations/dungeon/1.jpg"
import Dungeon2 from "../assets/backgrounds/locations/dungeon/2.jpg"
import Dungeon3 from "../assets/backgrounds/locations/dungeon/3.jpg"

import Quarry from "../assets/backgrounds/buildings/quarry.jpg"
import Lumbercamp from "../assets/backgrounds/buildings/lumbercamp.jpg"
import Storage from "../assets/backgrounds/buildings/storage.jpg"
import Kitchen from "../assets/backgrounds/buildings/kitchen.jpg"
import Mine from "../assets/backgrounds/buildings/mine.jpg"
import Fisher from "../assets/backgrounds/buildings/fisher.jpg"
import Claypit from "../assets/backgrounds/buildings/claypit.jpg"
import Workshop from "../assets/backgrounds/buildings/workshop.jpg"
import Home from "../assets/backgrounds/buildings/home.jpg"

import Peasants0 from "../assets/backgrounds/peasants/0.jpg"
import Peasants1 from "../assets/backgrounds/peasants/1.jpg"
import Peasants2 from "../assets/backgrounds/peasants/2.jpg"
import Peasants3 from "../assets/backgrounds/peasants/3.jpg"

export const bgDungeons = [Dungeon0, Dungeon1, Dungeon2, Dungeon3]
export const bgPeasants = [Peasants0, Peasants1, Peasants2, Peasants3]

export const bgQuarry = Quarry
export const bgLumbercamp = Lumbercamp
export const bgStorage = Storage
export const bgKitchen = Kitchen
export const bgMine = Mine
export const bgFisher = Fisher
export const bgClaypit = Claypit
export const bgWorkshop = Workshop
export const bgHome = Home