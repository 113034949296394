import portrait0 from '../assets/portraits/male/0.jpg';
import portrait1 from '../assets/portraits/male/1.jpg';
import portrait2 from '../assets/portraits/male/2.jpg';
import portrait3 from '../assets/portraits/male/3.jpg';
import portrait4 from '../assets/portraits/male/4.jpg';
import portrait5 from '../assets/portraits/male/5.jpg';
import portrait6 from '../assets/portraits/male/6.jpg';
import portrait7 from '../assets/portraits/male/7.jpg';
import portrait8 from '../assets/portraits/male/8.jpg';
import portrait9 from '../assets/portraits/male/9.jpg';
import portrait10 from '../assets/portraits/male/10.jpg';
import portrait11 from '../assets/portraits/male/11.jpg';
import portrait12 from '../assets/portraits/male/12.jpg';
import portrait13 from '../assets/portraits/male/13.jpg';
import portrait14 from '../assets/portraits/male/14.jpg';
import portrait15 from '../assets/portraits/male/15.jpg';
import portrait16 from '../assets/portraits/male/16.jpg';
import portrait17 from '../assets/portraits/male/17.jpg';
import portrait18 from '../assets/portraits/male/18.jpg';
import portrait19 from '../assets/portraits/male/19.jpg';
import portrait20 from '../assets/portraits/male/20.jpg';
import portrait21 from '../assets/portraits/male/21.jpg';
import portrait22 from '../assets/portraits/male/22.jpg';
import portrait23 from '../assets/portraits/male/23.jpg';
import portrait24 from '../assets/portraits/male/24.jpg';
import portrait25 from '../assets/portraits/male/25.jpg';
import portrait26 from '../assets/portraits/male/26.jpg';
import portrait27 from '../assets/portraits/male/27.jpg';
import portrait28 from '../assets/portraits/male/28.jpg';
import portrait29 from '../assets/portraits/male/29.jpg';
import portrait30 from '../assets/portraits/male/30.jpg';
import portrait31 from '../assets/portraits/male/31.jpg';
import portrait32 from '../assets/portraits/male/32.jpg';
import portrait33 from '../assets/portraits/male/33.jpg';
import portrait34 from '../assets/portraits/male/34.jpg';
import portrait35 from '../assets/portraits/male/35.jpg';
import portrait36 from '../assets/portraits/male/36.jpg';
import portrait37 from '../assets/portraits/male/37.jpg';
import portrait38 from '../assets/portraits/male/38.jpg';
import portrait39 from '../assets/portraits/male/39.jpg';
import portrait40 from '../assets/portraits/male/40.jpg';
import portrait41 from '../assets/portraits/male/41.jpg';
import portrait42 from '../assets/portraits/male/42.jpg';
import portrait43 from '../assets/portraits/male/43.jpg';
import portrait44 from '../assets/portraits/male/44.jpg';
import portrait45 from '../assets/portraits/male/45.jpg';
import portrait46 from '../assets/portraits/male/46.jpg';
import portrait47 from '../assets/portraits/male/47.jpg';
import portrait48 from '../assets/portraits/male/48.jpg';
import portrait49 from '../assets/portraits/male/49.jpg';
import portrait50 from '../assets/portraits/male/50.jpg';
import portrait51 from '../assets/portraits/male/51.jpg';
import portrait52 from '../assets/portraits/male/52.jpg';
import portrait53 from '../assets/portraits/male/53.jpg';
import portrait54 from '../assets/portraits/male/54.jpg';
import portrait55 from '../assets/portraits/male/55.jpg';
import portrait56 from '../assets/portraits/male/56.jpg';
import portrait57 from '../assets/portraits/male/57.jpg';
import portrait58 from '../assets/portraits/male/58.jpg';
import { getRandomNumber } from '../helper/mathHelper';

export const malePortraits = [
  portrait0, portrait1, portrait2, portrait3, portrait4, portrait5, portrait6, portrait7, portrait8, portrait9,
  portrait10, portrait11, portrait12, portrait13, portrait14, portrait15, portrait16, portrait17, portrait18, portrait19,
  portrait20, portrait21, portrait22, portrait23, portrait24, portrait25, portrait26, portrait27, portrait28, portrait29,
  portrait30, portrait31, portrait32, portrait33, portrait34, portrait35, portrait36, portrait37, portrait38, portrait39,
  portrait40, portrait41, portrait42, portrait43, portrait44, portrait45, portrait46, portrait47, portrait48, portrait49,
  portrait50, portrait51, portrait52, portrait53, portrait54, portrait55, portrait56, portrait57, portrait58
];


export const getRandomMalePortrait = () => malePortraits[getRandomNumber(0, malePortraits.length - 1)]