import { Building, Worker } from "../types/types";

export { }

declare global {
  interface Number {
    /**
    * Returns a padded number string.
    * 
    * ```
    * const n = 1;
    * n.padNum() // "01"
    * ```
    */
    padNum: () => string;
  }
}

// eslint-disable-next-line
Number.prototype.padNum = function (): string {
  return this.toString().padStart(2, "0")
}

export const createId = () => Math.random().toString(16).slice(2)

export const buildingPath = (building: Building) => `/assets/buildings/${building.id}/${building.id}-${building.level}.png`

export const getWorkerName = (worker: Worker) => `${worker.name} "${worker.nickname}" ${worker.lastName}`

export const toLetterCase = (string: string) => string.replace(/\b\w/g, c => c.toUpperCase())