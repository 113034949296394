import { Dispatch, SetStateAction, useState } from "react"
import ReactSlider from "react-slider"
import { Data } from "../types/types"
import Button from "./Button"
import Modal from "./Modal"

const Settings = ({
  data,
  setData,
  isOpen,
  setIsOpen,
}: {
  data: Data
  setData: Dispatch<SetStateAction<Data>>
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {

  const [creditsOpen, setCreditsOpen] = useState(false)
  const [keybindingsOpen, setKeybindingsOpen] = useState(false)

  const changeVolume = (volume: number) => {
    const d = { ...data }
    d.audio.volume = volume
    setData(d)
  }

  const deleteSave = () => {
    localStorage.removeItem("save")
    window.location.reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      data={data}
      title="Settings">
      <div className="flex flex-col gap-4 justify-center items-center">

        <div className="flex items-center gap-4 justify-center w-full">
          <div className="">Volume</div>
          <ReactSlider
            className="slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track slider-track"
            min={0}
            max={1}
            step={0.1}
            defaultValue={data.audio.volume}
            onChange={e => changeVolume(e)}
          />
          <div className="">{data.audio.volume * 100}</div>
        </div>
        <div className="flex flex-col gap-4">
          <Button onClick={() => deleteSave()}>
            Start new game
          </Button>
          <Button onClick={() => setCreditsOpen(true)}>Credits</Button>
          <Button onClick={() => setKeybindingsOpen(true)}>Keybindings</Button>

          <Modal
            isOpen={creditsOpen}
            setIsOpen={setCreditsOpen}
            data={data}
            title="Credits">
            <div className="text-center">
              <h3 className="text-xl">Music</h3>
              <p>
                Fantasy Motion<br />
                Alexander Nakarada (<a target="_blank" rel="noreferrer" href="www.creatorchords.com">www.creatorchords.com</a>) <br />
                Licensed under <a target="_blank" rel="noreferrer" href="https://creativecommons.org/licenses/by/4.0/">Creative Commons BY Attribution 4.0 License</a>
              </p>
            </div>
          </Modal>

          <Modal
            isOpen={keybindingsOpen}
            setIsOpen={setKeybindingsOpen}
            title="Key bindings"
            data={data}
          >
            <table>
              <tbody>
                <Keybinding direction="Up" key1="W" key2="Arrow Up" />
                <Keybinding direction="Down" key1="S" key2="Arrow Down" />
                <Keybinding direction="Left" key1="A" key2="Arrow Left" />
                <Keybinding direction="Right" key1="D" key2="Arrow Right" />
                <Keybinding direction="Home" key1="Space" />
              </tbody>
            </table>
          </Modal>
        </div>
      </div>
    </Modal>
  )
}

export default Settings

const Keybinding = ({
  direction,
  key1,
  key2,
}: {
  direction: string
  key1: string
  key2?: string
}) => (
  <tr>
    <td className="pr-8">{direction}</td>
    <td className="pr-4 text-center">{key1}</td>
    <td className="pr-4">{key2}</td>
  </tr>
)