import { IconHerb, IconPoultice, IconTincture, IconWillowBark } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types"

// Herb
export const resourceHerb: Resource = {
  id: createId(),
  name: "Herb",
  type: "herb",
  rarity: "common",
  
  icon: IconHerb,
  category: "medicine",
  
  tradeValue: 1,
};

// Healing Poultice
export const resourceHealingPoultice: Resource = {
  id: createId(),
  name: "Healing Poultice",
  type: "healingPoultice",
  rarity: "common",
  
  icon: IconPoultice,
  category: "medicine",
  
  tradeValue: 1,
};

// Medicinal Tincture
export const resourceMedicinalTincture: Resource = {
  id: createId(),
  name: "Medicinal Tincture",
  
  type: "medicinalTincture",
  rarity: "common",
  icon: IconTincture,
  category: "medicine",
  
  tradeValue: 1,
};

// Willow Bark
export const resourceWillowBark: Resource = {
  id: createId(),
  name: "Willow Bark",
  type: "willowBark",
  rarity: "common",
  
  icon: IconWillowBark,
  category: "medicine",
  
  tradeValue: 1,
};

// Export const for referencing all Medicinal resources
export const medicinalResources: Resource[] = [
  resourceHerb,
  resourceHealingPoultice,
  resourceMedicinalTincture,
  resourceWillowBark,
];
