import { useState } from "react"
import { IconZzz } from "../data/icons"
import { getEfficiency } from "../helper/dataHelper"
import { createId } from "../helper/stringHelper"
import { Building, Worker } from "../types/types"
import { WorkerDataTable } from "./DataTable"
import TooltipCustom from "./TooltipCustom"

interface WorkerPortraitProps {
  worker: Worker
  building?: Building
  size?: "small" | "medium" | "big"
  onClick?: () => void
  noInfo?: boolean
  noTooltip?: boolean
  isResting?: boolean
}

const WorkerPortrait = ({
  worker,
  building,
  size = "medium",
  onClick,
  noInfo = false,
  noTooltip = false,
  isResting = false,
}: WorkerPortraitProps) => {
  const [id] = useState(createId())
  return (
    <>
      <div
        className="relative w-max flex-shrink-0"
        onClick={onClick}
        data-tooltip-id={id}
        data-tooltip-place="right"
      >
        <img
          src={worker.portrait}
          alt=""
          className={`rounded-sm p-0.5 ${worker.id === "player" ? "border border-primary border-dashed" : " border border-primary"} ${size === "small" ? "w-8 h-8" : size === "medium" ? "w-12 h-12" : "w-16 h-16"}`}
        />
        {!noInfo && <>
          {building && <div className="absolute top-px left-px flex justify-center items-center p-1 rounded-sm leading-none text-sm">{getEfficiency(worker, building)}</div>}
          <div className="absolute right-1 bottom-1 top-1 w-1 inline-flex items-end">
            <div className="bg-primary w-full" style={{ height: ((worker.stamina.current / worker.stamina.max) * 100) + "%" }} />
          </div>
        </>}
        {(isResting || worker.resting) && <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full bg-primary bg-opacity-20 bg-center bg-no-repeat z-10" style={{ backgroundImage: `url(${IconZzz})`, backgroundSize: 36 }} />}
      </div>
     {!noTooltip && <TooltipCustom id={id} clickable>
        <WorkerDataTable worker={worker} />
      </TooltipCustom>}
    </>
  )
}

export default WorkerPortrait