import { Dispatch, SetStateAction } from "react";
import { Data, Sex } from "./types";

export interface Skill {
  name: string;
  description: string;
  perform: (
    data: Data,
    setSelectedEnemy: Dispatch<SetStateAction<Enemy | undefined>>,
    target?: Enemy
  ) => void;
}

export interface Enemy {
  id: string;
  name?: string;
  lastName?: string;
  nickname?: string;
  portrait?: string;
  sex?: Sex;
  health: { current: number; max: { base: number; modifiers?: number[] } };
  stamina: { current: number; max: { base: number; modifiers?: number[] } };
  strength: number;
  inventory: {};
}

export const strengthMultiplier = 0.5;

export const attack: Skill = {
  name: "Attack",
  description: "Attack an enemy",
  perform: (
    data: Data,
    setSelectedEnemy: Dispatch<SetStateAction<Enemy | undefined>>,
    target?: Enemy
  ) => {
    const d = { ...data };

    if (target) {
      const damageDealt = Math.floor(d.player.strength * strengthMultiplier);
      target.health.current = Math.max(0, target.health.current - damageDealt);

      if (target.health.current <= 0) {
        d.dungeon.currentEnemies = (d.dungeon.currentEnemies || []).filter(
          (e) => e.id !== target.id
        );
        setSelectedEnemy(undefined); // Clear selected enemy after defeating
      }

      d.dungeon.log.push(
        `Player dealt <span class="text-primary">${damageDealt}</span> damage to ${target.name} ${target.lastName}`
      );
    }
  },
};

export const heal: Skill = {
  name: "Heal",
  description: "Restore health to the player.",
  perform: (
    data: Data,
    setSelectedEnemy: Dispatch<SetStateAction<Enemy | undefined>>,
    target?: Enemy
  ) => {
    const d = { ...data };
    const healingAmount = 20; // You can adjust this value as needed

    // Heal the player instead of targeting an enemy
    d.player.health.current = Math.min(
      d.player.health.max,
      d.player.health.current + healingAmount
    );

    d.dungeon.log.push(
      `Player healed <span class="text-success">${healingAmount}</span>`
    );
  },
};

