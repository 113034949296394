import { hour } from "../../data/_data";
import { IconPickaxe } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Pickaxe, Tool } from "../../types/types";
import { resourceWood } from "../resources/basic";
import { resourceCopperIngot, resourceIronIngot } from "../resources/ingots";

// Copper Pickaxe
export const toolCopperPickaxe: Pickaxe = {
  id: createId(),
  name: "Copper Pickaxe",
  rarity: "rare",
  materialType: "copperPickaxe",
  icon: IconPickaxe,
  efficiency: 1,
  type: "pickaxe",
  craftingCost: [
    { resource: resourceWood, amount: 1 },
    { resource: resourceCopperIngot, amount: 2 },
  ],
  tradeValue: 10,
  status: { max: 100, current: 100 },
  time: hour * 8,
};

// Iron Pickaxe
export const toolIronPickaxe: Pickaxe = {
  id: createId(),
  name: "Iron Pickaxe",
  rarity: "epic",
  materialType: "ironPickaxe",
  icon: IconPickaxe,
  efficiency: 3,
  type: "pickaxe",
  craftingCost: [
    { resource: resourceIronIngot, amount: 2 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 12,
  status: { max: 100, current: 100 },
  time: hour * 16,
};

// Export const for referencing all pickaxes
export const toolsPickaxes: Tool[] = [toolCopperPickaxe, toolIronPickaxe];
