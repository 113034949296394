import { TimeOfDay } from "../types/types"


const Night = ({ timeOfDay }: { timeOfDay: TimeOfDay }) => {
  return (
    <div
      className="night absolute z-30 w-full h-full top-0 left-0 bottom-0 right-0 bg-black pointer-events-none transition-opacity duration-[10000ms]"
      style={{
        opacity: timeOfDay === "Early Morning" ? "20%" :
          timeOfDay === "Late Morning" ? "0%" :
            timeOfDay === "Noon" ? "0%" :
              timeOfDay === "Early Afternoon" ? "0%" :
                timeOfDay === "Late Afternoon" ? "0%" :
                  timeOfDay === "Evening" ? "30%" :
                    timeOfDay === "Night" ? "70%" :
                      "0%"
      }} />
  )
}

export default Night