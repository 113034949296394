import { getResourceId } from "../../helper/dataHelper"
import { BuildingKitchen, CookedFood, Cost } from "../../types/types"
import { IconButton } from "../Button"
import { Icon } from "../Icon"
import MenuBuilding, { MenuBuildingProps } from "./MenuBuilding"


interface MenuBuildingKitchenProps extends MenuBuildingProps {
  building: BuildingKitchen
}

const MenuBuildingKitchen = ({
  building,
  data,
  setData,
}: MenuBuildingKitchenProps) => {

  const handleRecipeSelection = (food: CookedFood) => {
    const d = { ...data }
    const kitchen = d.buildings.kitchen
    kitchen.selectedRecipes.some(f => f.type === food.type) ? kitchen.selectedRecipes = kitchen.selectedRecipes.filter(f => f.type !== food.type) : kitchen.selectedRecipes.push(food)
    console.log(d.buildings.kitchen.selectedRecipes)
    //kitchen.toolToProduce = undefined
    setData(d)
  };

  return (
    <MenuBuilding
      building={building}
      data={data}
      setData={setData}
    >
      <div className="recipes">
        <div className="mb-1">Recipes</div>
        <div className="surface-box gap-2 flex flex-wrap">
          {building.recipes.map((recipe, i) => {
            const kitchen = data.buildings.kitchen
            return (
              <>
                <IconButton
                  key={i}
                  icon={recipe.icon}
                  onClick={() => handleRecipeSelection(recipe)}
                  active={kitchen.selectedRecipes.some(f => f.type === recipe.type)}
                  progress={{ current: recipe.time.current, max: recipe.time.max }}
                  color={recipe.rarity}
                >
                  <div className="flex flex-col gap-2 text-sm">
                    <table className="">
                      <tbody>
                        <tr>
                          <td colSpan={2}>{recipe.name}</td>
                        </tr>
                        {recipe.craftingCost.map((cost: Cost, i) => (
                          <tr key={i}>
                            <td className='pr-2'></td>
                            <td className='w-full'>
                              <div className="flex gap-2 items-center">
                                <Icon size="small" icon={cost.resource.icon} />{data.resources[getResourceId(cost.resource)].length}/{cost.amount}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </IconButton >
              </>
            )
          })}
        </div>
      </div>
    </MenuBuilding >
  )
}

export default MenuBuildingKitchen