import { ReactNode } from "react"
import { Tooltip } from "react-tooltip"

const TooltipCustom = ({
  children,
  id,
  clickable = false,
}: {
  children: ReactNode
  id: string
  clickable?: boolean
}) => {
  return (
    <Tooltip id={id} noArrow place="right" delayHide={0} delayShow={500} opacity={1} clickable={clickable} className="z-10">{children}</Tooltip>
  )
}

export default TooltipCustom