import { getRandomNumber } from "../helper/mathHelper";
import { Sex } from "../types/types";

export const maleNames = [
  "Cedric",
  "Percival",
  "Gideon",
  "Baldwin",
  "Thaddeus",
  "Leofric",
  "Godfrey",
  "Reynard",
  "Alaric",
  "Tristan"
]

export const femaleNames = [
  "Isolde",
  "Eleanor",
  "Aveline",
  "Rosalind",
  "Seraphina",
  "Elowen",
  "Rowena",
  "Sybilla",
  "Elysande",
  "Isabeau"
]

export const lastNames = [
  "Wolfgang", "Fitzgerald", "Hawthorne", "Blackwood", "Montgomery",
  "Ainsworth", "Lancaster", "Wycliffe", "Fletcher", "Harrington",
  "Fairchild", "Lanternwood", "Ravenscroft", "Aldercroft", "Hollingsworth",
  "Windsor", "Thornfield", "Hartfield", "Windermere", "Everhart",
  "Stonebridge", "Lockewood", "Rutherford", "Meriwether", "Fairfax",
  "Bannister", "Dunstable", "Stanhope", "Ashbourne", "Rookwood",
  "Harrowgate", "Thistlewick", "Quincey", "Alderidge", "Bromfield",
  "Hedgebrook", "Willoughby", "Northwood", "Wetherby", "Sablecroft",
  "Ridgewell", "Dunham", "Ashcroft", "Havenridge", "Winterbourne",
  "Hawksworth", "Meadowcroft", "Fallowfield", "Ravenhurst"
]

export const nicknames = [
  "Shadowblade",
  "Ironheart",
  "Silent Serpent",
  "Nightshade",
  "The Phantom",
  "Frostbite",
  "Stormcloak",
  "Obsidian Edge",
  "Stonefist",
  "The Ghost",
  "Thunderclaw",
  "Silent Stalker",
  "Darkblade",
  "Thunderbolt",
  "Ironmask",
  "The Enigma",
  "Grimshadow",
  "The Iron Wolf",
  "Frostfire",
  "Vortex Blade",
  "Stoneheart",
  "Frostwind",
  "Silent Thunder",
  "The Blackthorn",
  "Ironclad",
  "Iron Golem",
  "The Whisper",
  "The Howler",
  "The Silent",
  "The Wraith",
  "Nightscourge",
  "Iron Sphinx",
  "Silent Storm",
  "The Silent Reaper",
  "The Invincible",
  "The Unseen",
  "The Untouchable",
  "The Shadow",
  "The Steel Shadow",
  "Iron Viper",
  "The Black Hawk",
  "The Red Blade",
  "The Iron Phoenix",
  "The Silent Marauder",
  "Silent Whisper",
  "Obsidian Terror",
  "The Iron Paladin"
]

export const getRandomName = (sex: Sex): string => sex === "male" ? maleNames[getRandomNumber(0, maleNames.length - 1)] : femaleNames[getRandomNumber(0, femaleNames.length - 1)]
export const getRandomLastName = (): string => lastNames[getRandomNumber(0, lastNames.length - 1)]
export const getRandomNickname = (): string => nicknames[getRandomNumber(0, nicknames.length - 1)]