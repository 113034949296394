import { IconTrowel } from '../../data/icons';
import { createId } from '../../helper/stringHelper';
import { Tool } from '../../types/types';
import { resourceWood } from '../resources/basic';
import { resourceCopperIngot, resourceIronIngot } from '../resources/ingots';

// Wooden Trowel
export const toolWoodenTrowel: Tool = {
  id: createId(),
  name: 'Wooden Trowel',
  rarity: "common",
  materialType: 'woodenTrowel',
  icon: IconTrowel,
  efficiency: 1,
  type: 'trowel',
  craftingCost: [
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 8,
  status: { max: 100, current: 100 },
  time: 10000,
};

// Copper Trowel
export const toolCopperTrowel: Tool = {
  id: createId(),
  name: 'Copper Trowel',
  rarity: "uncommon",
  materialType: 'copperTrowel',
  icon: IconTrowel,
  efficiency: 1.5,
  type: 'trowel',
  craftingCost: [
    { resource: resourceCopperIngot, amount: 2 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 8,
  status: { max: 100, current: 100 },
  time: 10000,
};

// Iron Trowel
export const toolIronTrowel: Tool = {
  id: createId(),
  name: 'Iron Trowel',
  rarity: "rare",
  materialType: 'ironTrowel',
  icon: IconTrowel,
  efficiency: 1.8,
  type: 'trowel',
  craftingCost: [
    { resource: resourceIronIngot, amount: 2 },
    { resource: resourceWood, amount: 1 },
  ],
  tradeValue: 10,
  status: { max: 100, current: 100 },
  time: 10000,
};

// Export const for referencing all trowels
export const toolsTrowels: Tool[] = [toolCopperTrowel, toolIronTrowel];
