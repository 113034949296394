import { IconCoin } from "../../data/icons"
import { getMaxStorage, getResourceInfo } from "../../helper/dataHelper"
import { BuildingStorage, Resource, ResourceType } from "../../types/types"
import { ToolDataTable } from "../DataTable"
import { Icon, IconTile } from "../Icon"
import TooltipCustom from "../TooltipCustom"
import MenuBuilding, { MenuBuildingProps } from "./MenuBuilding"

interface MenuBuildingStorageProps extends MenuBuildingProps {
  building: BuildingStorage
}

const MenuBuildingStorage = ({
  building,
  data,
  setData,
}: MenuBuildingStorageProps) => {
  const d = { ...data }
  const resources: [{ resource: Resource[], key: ResourceType }?] = []
  Object.entries(d.resources).forEach(r => {
    const [key, resource] = r
    resources.push({ resource: resource, key: key as ResourceType })
  })

  return (
    <MenuBuilding
      building={building}
      data={data}
      setData={setData}
    >
      <div className="w-full flex flex-col overflow-y-auto">

        <div className="mb-1">Resources</div>
        {resources?.length > 0 && (
          <div className="grid grid-cols-5 gap-2 w-full surface-box overflow-y-auto scrollbar-thin">
            {resources.map((r, i) => {
              if (r) {
                const resource = r.resource
                const resourceInfo = getResourceInfo(r.key)
                return resource && (
                  <div
                    className="flex items-center gap-2 py-1 px-2 border border-primary bg-surface rounded-sm"
                    key={i}
                    data-tooltip-id={`storage-${i}`}
                  >
                    <Icon size="medium" icon={getResourceInfo(r.key).icon} />
                    <div className="flex-1 text-center">{resource.length}/{getMaxStorage(d.buildings.storage)}</div>
                    <TooltipCustom id={`storage-${i}`}>
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={2}>{resourceInfo.name}</td>
                            <td><div className="flex items-center gap-2"><Icon size="small" icon={IconCoin} />{resourceInfo.tradeValue}</div></td>
                          </tr>
                        </tbody>
                      </table>
                    </TooltipCustom>
                  </div>
                )
              } else return <></>
            })}
          </div>
        )}

        <div className="mt-4 mb-1">Tools</div>
        <div className="surface-box w-full">
          <div className="flex flew-wrap gap-2 w-full">
            {d.toolItems.map((tool, i) => (
              <IconTile item={tool}>
                <ToolDataTable tool={tool} />
              </IconTile>
            ))}
          </div>
        </div>
      </div>
    </MenuBuilding >
  )
}

export default MenuBuildingStorage