import { getRandomNumber } from "../helper/mathHelper";
import { createId } from "../helper/stringHelper";
import { toolCopperAxe } from "../items/tools/axe";
import { toolBackpack } from "../items/tools/backpack";
import { toolWoodenFishingRod } from "../items/tools/fishingrod";
import { toolWoodenHammer } from "../items/tools/hammer";
import { toolWoodenKitchenUtensils } from "../items/tools/kitchenUtensils";
import { toolCopperPickaxe } from "../items/tools/pickaxe";
import { toolWoodenTrowel } from "../items/tools/trowel";
import { Data } from "../types/types";
import { buildingClay, buildingFisher, buildingHerbalist, buildingHome, buildingKitchen, buildingLumbercamp, buildingQuarry, buildingStorage, buildingWorkshop, cityBuildingMarket, cityBuildingTavern } from "./buildings";
import { createWorker, player } from "./player";
import { techBridge, techRoads, techTorch } from "./tech";

const worker1 = createWorker()
const worker2 = createWorker()
const worker3 = createWorker()

export const defaultData: Data = {
  version: "0.0.65",
  newGame: true,
  player: player,
  lightsOn: false,
  location: {
    x: 500,
    y: 0,
  },
  buildings: {
    home: buildingHome,
    storage: buildingStorage,
    quarry: buildingQuarry,
    lumbercamp: buildingLumbercamp,
    claypit: buildingClay,
    workshop: buildingWorkshop,
    fisher: buildingFisher,
    kitchen: buildingKitchen,
    herbalist: buildingHerbalist,
  },
  toolItems: [
    { ...toolCopperPickaxe, id: createId() },
    { ...toolBackpack, id: createId() },
    { ...toolCopperAxe, id: createId() },
    { ...toolCopperAxe, id: createId() },
    { ...toolWoodenFishingRod, id: createId() },
    { ...toolWoodenKitchenUtensils, id: createId() },
    { ...toolWoodenKitchenUtensils, id: createId() },
    { ...toolWoodenHammer, id: createId() },
    { ...toolWoodenTrowel, id: createId() },
  ],
  resources: {
    stick: [],
    wood: [],
    stone: [],
    clay: [],

    wool: [],
    hide: [],
    manure: [],

    coal: [],
    peat: [],
    charcoal: [],
    oil: [],

    fish: [],
    mushroom: [],

    friedFish: [],
    mushroomSoup: [],
    bread: [],
    cheese: [],
    apple: [],
    carrot: [],
    onion: [],
    milk: [],

    copperIngot: [],
    ironIngot: [],
    silverIngot: [],
    goldIngot: [],

    ironOre: [],
    copperOre: [],
    silverOre: [],
    goldOre: [],

    herb: [],
    healingPoultice: [],
    medicinalTincture: [],
    willowBark: [],

    brick: [],
    glass: [],
    leather: [],
    woodenNail: [],
    copperNail: [],
    ironNail: [],
    log: [],

    goldCoin: [],
    gemstone: [],
  },
  tech: {
    torches: techTorch,
    roads: techRoads,
    bridge: techBridge,
  },
  workers: [player, worker1, worker2, worker3],
  unemployedWorkers: [player, worker1, worker2, worker3],
  time: {
    isGameRunning: false,
    speed: 1000,
    minute: 59,
    hour: 7,
    isDaytime: true,
    timeOfDay: "Early Morning",
    isWorktime: false
  },
  city: {
    active: false,
    buildings: {
      tavern: cityBuildingTavern,
      market: cityBuildingMarket,
    }
  },
  dungeon: {
    active: false,
    currentEnemies: [],
    log: [],
    background: getRandomNumber(0, 3),
  },
  audio: {
    volume: 0.2,
    muted: true,
  }
}