import { IconCoal, IconPeat, IconSparkle } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource, Fuel } from "../../types/types";

// Coal
export const resourceCoal: Fuel = {
  id: createId(),
  name: "Coal",
  type: "coal",
  rarity: "common",
  
  icon: IconCoal,
  byproduct: undefined,
  category: "fuel",
  
  tradeValue: 1,
  status: { max: 100, current: 100 },
};

// Peat
export const resourcePeat: Fuel = {
  id: createId(),
  name: "Peat",
  type: "peat",
  rarity: "common",
  
  icon: IconPeat,
  byproduct: undefined,
  category: "fuel",
  
  tradeValue: 1,
  status: { max: 100, current: 100 },
};

// Charcoal
export const resourceCharcoal: Fuel = {
  id: createId(),
  name: "Charcoal",
  type: "charcoal",
  rarity: "common",
  
  icon: IconCoal,
  byproduct: undefined,
  category: "fuel",
  
  tradeValue: 1,
  status: { max: 100, current: 100 },
};

// Oil
export const resourceOil: Fuel = {
  id: createId(),
  name: "Oil",
  type: "oil",
  rarity: "common",
  
  icon: IconSparkle,
  byproduct: undefined,
  category: "fuel",
  
  tradeValue: 1,
  status: { max: 100, current: 100 },
};

// Export const for referencing all Energy resources
export const energyResources: Resource[] = [resourceCoal, resourcePeat, resourceCharcoal, resourceOil];


