import { useEffect, useState } from "react"
import { Trash2 } from "react-feather"
import { bgDungeons } from "../data/backgrounds"
import { IconBattle, IconBook, IconRun } from '../data/icons'
import { createEnemy } from "../data/player"
import { getRandomNumber } from "../helper/mathHelper"
import { Enemy, Skill, attack, heal, strengthMultiplier } from "../types/dungeon"
import { Data } from "../types/types"
import { IconButton } from "./Button"
import ProgressBar from "./Progressbar"
import { getFullName } from "../helper/dataHelper"

const Dungeon = ({
  data,
  setData
}: {
  data: Data
  setData: React.Dispatch<React.SetStateAction<Data>>
}) => {
  const [selectedEnemy, setSelectedEnemy] = useState<Enemy | undefined>(undefined);
  const [turn, setTurn] = useState<"enemy" | "player">("player");

  const createEnemies = () => {
    const d = { ...data };
    if (d.dungeon.currentEnemies && d.dungeon.currentEnemies.length <= 0) {
      const amount: number = getRandomNumber(1, 4)
      const newEnemies = Array.from({ length: amount }, () => createEnemy())
      d.dungeon.currentEnemies = [...(d.dungeon.currentEnemies || []), ...newEnemies]
      setData(d)
    }
  }

  const handleSkill = (skill: Skill, target?: Enemy) => {
    skill.perform(data, setSelectedEnemy, target);
    setTurn("enemy");
  };

  useEffect(() => {
    const d = { ...data }
    if (turn === "enemy") {
      d.dungeon.currentEnemies?.forEach(enemy => {
        const damageDealt = Math.floor(enemy.strength * strengthMultiplier)
        data.player.health.current = Math.max(0, data.player.health.current - damageDealt)
        d.dungeon.log.push(`${enemy.name} ${enemy.lastName} dealt <span class="text-primary">${damageDealt}</span> damage to Player`)
        scrollToBottom()
      })
      setTurn("player")
    }
    // eslint-disable-next-line
  }, [turn])

  function scrollToBottom() {
    if (document.getElementById("log")) document.getElementById("log")!.scroll({ top: document.getElementById("log")!.scrollHeight, behavior: 'smooth' });
  }

  const deleteSave = () => {
    localStorage.removeItem("save")
    window.location.reload()
  }

  const leaveDungeon = () => {
    const d = { ...data }
    d.dungeon.active = false
    setData(d)
  }

  useEffect(() => {
    createEnemies()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-background w-full h-full flex flex-col overflow-hidden bg-cover p-2 gap-2" style={{ backgroundImage: `url(${bgDungeons[data.dungeon.background]})` }}>
      <div onClick={() => deleteSave()} className="absolute top-0 right-0 p-2">
        <Trash2 size={16} />
      </div>
      <div className="flex flex-1 gap-2">
        <div className="flex flex-col flex-1">

          <div className="enemies grid grid-cols-4 gap-1 p-1 flex-1 items-start">
            {data.dungeon.currentEnemies?.map(enemy => {
              return (
                <div className={`p-4 border bg-surface border-primary rounded-sm inline-flex gap-2 w-full  ${enemy === selectedEnemy ? "border-fancy" : "border border-primary"}`} onClick={() => setSelectedEnemy(enemy)}>
                  <div className="w-full flex flex-col items-end gap-2">
                    <div className="">{enemy.health.current} / {enemy.health.max.base}</div>
                    <ProgressBar type="health" max={enemy.health.max.base} current={enemy.health.current} size="small" />
                    <div className="text-sm">{enemy.name} {enemy.lastName}</div>
                  </div>
                  <div className="h-[70px] aspect-square border-primary border rounded-sm">
                    <img src={enemy.portrait} alt="" className="w-full h-full object-conver" />
                  </div>
                </div>
              )
            })}
          </div>

          <div className="player flex gap-2">
            <div className="flex gap-4 bg-surface border-fancy">
              <div className="h-[100px] aspect-square border-primary border rounded-sm">
                <img src={data.player.portrait} alt="" className="w-full h-full object-conver" />
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="flex justify-end">{data.player.health.current} / {data.player.health.max}</div>
                <div className="min-w-[200px]">
                  <ProgressBar size="small" max={data.player.health.max} current={data.player.health.current} />
                </div>
                <div className="">{getFullName(data.player)}</div>
              </div>
            </div>
            <div className="flex gap-2 bg-surface border-fancy flex-1">
              {turn === "player" && (
                <>
                  <IconButton icon={IconBattle} onClick={() => handleSkill(attack, selectedEnemy)}>
                    <div className="text-primary">{attack.name}</div>
                    <div className="">{attack.description}</div>
                  </IconButton>
                  <IconButton icon={IconBook} onClick={() => handleSkill(heal, selectedEnemy)}>
                    <div className="text-primary">{heal.name}</div>
                    <div className="">{heal.description}</div>
                  </IconButton>
                  <IconButton icon={IconRun} onClick={() => leaveDungeon()} />
                  {/* Add more buttons for other skills */}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="log bg-surface flex flex-col gap-2 border-fancy w-[300px] max-h-screen overflow-y-auto scrollbar-thin">
          {data.dungeon.log.map((message: string) => {
            return (
              <div id="log" className="text-sm" dangerouslySetInnerHTML={{ __html: message }} data-aos="fade-right" data-aos-duration="1000" data-aos-anchor="log"></div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Dungeon