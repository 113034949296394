import { CookedFood, Data } from "../types/types";
import { getResourceId } from "./dataHelper";
import { createId } from "./stringHelper";


export const runKitchen = async (
  data: Data,
  setData: React.Dispatch<React.SetStateAction<Data>>
) => {
  const d = { ...data };
  const kitchen = d.buildings.kitchen
  let manpower = 0
  kitchen.workers.forEach(worker => manpower += worker.perks.cooking.level)
  const kitchenEfficiency = kitchen.levels[kitchen.level].efficiency

  // Iterate over selected recipes in the kitchen
  for (const selectedRecipe of kitchen.selectedRecipes) {
    const recipeData = d.resources[getResourceId(selectedRecipe)];

    const hasEnoughResources = selectedRecipe.craftingCost.every((cost) => {
      const resourceId = getResourceId(cost.resource);
      return d.resources[resourceId].length >= cost.amount;
    });

    if (hasEnoughResources) {
      // Check if the recipe is still in production
      if (selectedRecipe.time.current === 0) {
        // Deduct resources for the selected recipe
        selectedRecipe.craftingCost.forEach((cost) => {
          const resourceId = getResourceId(cost.resource);
          d.resources[resourceId].splice(0, cost.amount);
        });
        selectedRecipe.time.current = Math.min(selectedRecipe.time.current + manpower * kitchenEfficiency, selectedRecipe.time.max);
      }
    } else {
      console.log(`Not enough resources to produce ${selectedRecipe.name}!`);
    }
    if (selectedRecipe.time.current < selectedRecipe.time.max && selectedRecipe.time.current !== 0) {
      selectedRecipe.time.current = Math.min(selectedRecipe.time.current + manpower * kitchenEfficiency, selectedRecipe.time.max);

    }
    
    if (selectedRecipe.time.current >= selectedRecipe.time.max) {
      // Create the produced item
      const producedItem: CookedFood = {
        id: createId(),
        name: selectedRecipe.name,
        category: selectedRecipe.category,
        rarity: selectedRecipe.rarity,
        icon: selectedRecipe.icon,
        type: selectedRecipe.type,
        craftingCost: selectedRecipe.craftingCost,
        tradeValue: selectedRecipe.tradeValue,
        time: selectedRecipe.time,
        status: { max: selectedRecipe.status.max, current: selectedRecipe.status.current },
        satisfaction: selectedRecipe.satisfaction,
        reduceHunger: selectedRecipe.reduceHunger,
      };

      // Add the produced item to the kitchen's output
      recipeData.push(producedItem);
      selectedRecipe.time.current = 0
    }
  }

  setData(d);
};