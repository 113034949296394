import AOS from 'aos';
import 'aos/dist/aos.css';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Pause, Play, Volume, VolumeX } from 'react-feather';
import ScrollContainer from 'react-indiana-drag-scroll';
import useState from 'react-usestateref';
import { IconButton } from './components/Button';
import Dungeon from './components/Dungeon';
import Forest from './components/Forest';
import { Icon } from './components/Icon';
import { MenuAssigments } from './components/MenuAssignments';
import Modal, { BuildingTitle } from './components/Modal';
import NewGame from './components/NewGame';
import Night from './components/Night';
import Settings from './components/Settings';
import TooltipCustom from './components/TooltipCustom';
import WorkerPortrait from './components/WorkerPortrait';
import MenuBuildingHome from './components/buildings/MenuBuildingHome';
import MenuBuildingKitchen from './components/buildings/MenuBuildingKitchen';
import MenuBuildingProduction from './components/buildings/MenuBuildingProduction';
import MenuBuildingStorage from './components/buildings/MenuBuildingStorage';
import MenuBuildingWorkshop from './components/buildings/MenuBuildingWorkshop';
import { bgClaypit, bgFisher, bgHome, bgKitchen, bgLumbercamp, bgQuarry, bgStorage, bgWorkshop } from './data/backgrounds';
import { defaultData } from './data/data';
import { IconBattle, IconMap, IconPerson, IconScroll, IconTorch, getTimeOfDayIcon } from './data/icons';
import { getFullName, getMaxStorage, getResourceId, getResourceInfo, isFood, isIngredient } from './helper/dataHelper';
import { runKitchen } from './helper/kitchenHelper';
import { runStorage } from './helper/storageHelper';
import "./helper/stringHelper";
import { buildingPath } from './helper/stringHelper';
import { runProductionBuilding, runWorkshopProduction, unemployedWork } from './helper/workHelper';
import { manageHunger, restWorkers } from './helper/workerHelper';
import './styles/main.scss';
import { Building, BuildingType, CityBuilding, Data, Food, Resource, ResourceType } from './types/types';
import MenuTavern from './components/city/MenuTavern';

function App() {

  let loadedSave: Data = localStorage.getItem("save") ? JSON.parse(localStorage.getItem("save")!) : defaultData
  if (loadedSave.version !== defaultData.version) loadedSave = defaultData
  const [data, setData] = useState<Data>(loadedSave)

  const container = useRef<HTMLDivElement>(null)
  const containerCity = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (container.current) container.current.scrollTo(data.location.x, data.location.y)
    // eslint-disable-next-line
  }, [data.newGame]);

  const setLocation = () => {
    if (container.current) {
      setData(od => ({ ...od, location: { x: container.current!.scrollLeft, y: container.current!.scrollTop } }))
    }
  }
  const setLocationCity = () => {
    if (container.current) {
      setData(od => ({ ...od, location: { x: container.current!.scrollLeft, y: container.current!.scrollTop } }))
    }
  }

  const [menuAssignmentsOpen, setMenuAssignmentsOpen] = useState(false)
  const [menuHomeOpen, setMenuHomeOpen] = useState(false)
  const [menuQuarryOpen, setMenuQuarryOpen] = useState(false)
  const [menuLumbercampOpen, setMenuLumbercampOpen] = useState(false)
  const [menuHerbalistOpen, setMenuHerbalistOpen] = useState(false)
  const [menuKitchenOpen, setMenuKitchenOpen] = useState(false)
  const [menuWorkshopOpen, setMenuWorkshopOpen] = useState(false)
  const [menuStorageOpen, setMenuStorageOpen] = useState(false)
  const [menuClaypitOpen, setMenuClaypitOpen] = useState(false)
  const [menuFisherOpen, setMenuFisherOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)

  const [tavernOpen, setTavernOpen] = useState(false)
  const [marketOpen, setMarketOpen] = useState(false)

  const visitCity = () => {
    setData(od => ({ ...od, city: { ...od.city, active: true } }))
  }

  const leaveCity = () => {
    setData(od => ({ ...od, city: { ...od.city, active: false } }))
  }

  const closeAllMenus = (keepOpen?: Dispatch<SetStateAction<boolean>>) => {
    setMenuHomeOpen(false)
    setMenuQuarryOpen(false)
    setMenuLumbercampOpen(false)
    setMenuHerbalistOpen(false)
    setMenuKitchenOpen(false)
    setMenuWorkshopOpen(false)
    setMenuStorageOpen(false)
    setMenuClaypitOpen(false)
    setMenuFisherOpen(false)
    setSettingsOpen(false)
  }

  const runTime = async () => {
    setData(od => ({ ...od, time: { ...od.time, minute: (od.time.minute + 1) % 60 } }))
    /* setData((oldData) => {
      const d = { ...oldData };
      d.time.minute = (d.time.minute + 1) % 60;
      return d;
    }); */
  };

  const runReduceStatus = () => {
    setData((prevData) => {
      const d = { ...prevData };
      Object.entries(d.resources).forEach((resourceEntry) => {
        const [resourceType, resourceArray] = resourceEntry;
        d.resources[resourceType as ResourceType] = resourceArray.filter((item) => {
          if (isFood(item) || isIngredient(item)) {
            const food = item as Food;
            if (food.status && food.status.current > 0) {
              food.status.current = food.status.current - 1;
              return true; // Keep the item in the array
            } else {
              return false; // Remove the item from the array
            }
          }
          return true; // Keep non-food items in the array
        });
      });
      return d;
    });
  };

  useEffect(() => {
    setData(oldData => {
      const d = { ...oldData }
      d.time.isDaytime = d.time.hour >= 5 && d.time.hour < 21

      if (d.time.minute === 59) {
        d.time.hour = (d.time.hour + 1) % 24
      }

      if (d.time.hour >= 5 && d.time.hour < 8) d.time.timeOfDay = "Early Morning"
      else if (d.time.hour >= 8 && d.time.hour < 12) d.time.timeOfDay = "Late Morning"
      else if (d.time.hour >= 12 && d.time.hour < 13) d.time.timeOfDay = "Noon"
      else if (d.time.hour >= 13 && d.time.hour < 15) d.time.timeOfDay = "Early Afternoon"
      else if (d.time.hour >= 15 && d.time.hour < 17) d.time.timeOfDay = "Late Afternoon"
      else if (d.time.hour >= 17 && d.time.hour < 21) d.time.timeOfDay = "Evening"
      else if (d.time.hour >= 21 || d.time.hour < 5) d.time.timeOfDay = "Night"
      else d.time.timeOfDay = "Noon"

      if (
        d.time.timeOfDay === "Late Morning" ||
        d.time.timeOfDay === "Early Afternoon" ||
        d.time.timeOfDay === "Late Afternoon" ||
        d.time.timeOfDay === "Evening"
      ) d.time.isWorktime = true
      else d.time.isWorktime = false

      if (d.time.isWorktime) {
        if ((d.buildings.workshop.selectedRecipe || d.buildings.workshop.selectedResearch)) runWorkshopProduction(data, setData);
        if (d.buildings.kitchen.selectedRecipes && d.buildings.kitchen.selectedRecipes.length > 0) runKitchen(data, setData)
      }

      return d
    })
    runProductionBuilding(data.buildings.quarry, data, setData)
    runProductionBuilding(data.buildings.lumbercamp, data, setData)
    runProductionBuilding(data.buildings.fisher, data, setData)
    runProductionBuilding(data.buildings.herbalist, data, setData)
    runStorage(data, setData)
    runReduceStatus()
    restWorkers(data, setData)
    runWooodConsumption()
    manageHunger(data, setData)
    unemployedWork(data, setData)

    // eslint-disable-next-line
  }, [data.time.minute])

  const newSave = () => localStorage.setItem("save", JSON.stringify(data))

  const toggleLight = async () => {
    const d = { ...data };

    if (d.tech.torches.active) {
      if (d.resources.wood.length > 1) {
        d.lightsOn = !d.lightsOn;
        setData(d);
      } else console.log("Not enough wood.")
    } else console.log("You don't know how to use torches yet.");
  };

  // Function to start wood consumption
  const runWooodConsumption = async () => {
    const d = { ...data };
    const wood = d.resources.wood;
    if (d.lightsOn && d.tech.torches.active) {
      if (wood.length > 0) {
        wood.splice(0, 1)
      } else {
        d.lightsOn = false;
      }
    }
    setData(d);
  };

  useEffect(() => {
    newSave()
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;

    if (data.time.isGameRunning) {
      interval = setInterval(() => {
        runTime()
      }, data.time.speed)
    }

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [data.time.isGameRunning, data.time.speed])


  const togglePause = async () => {
    setData(oldData => {
      const d = { ...oldData }
      d.time.isGameRunning = !d.time.isGameRunning
      return d
    })
  }

  const enterDungeon = () => {
    setData(od => ({ ...od, dungeon: { ...od.dungeon, active: true } }))
  }

  const scrollToBuilding = (building: BuildingType) => {
    const b = data.buildings[building]
    const level = b.levels[b.level]
    const coords = level.coords

    const img = new Image()
    img.src = buildingPath(b)

    img.onload = () => {
      const w = img.width
      const h = img.height
      container.current?.scrollTo(
        ((coords.x / 2 + (w / 2))) - (document.documentElement.clientWidth / 2),
        ((coords.y / 2 + (h / 2))) - (document.documentElement.clientHeight / 2),
      )
    }
  }

  useEffect(() => {
    AOS.init()
    document.addEventListener("keydown", (e) => {
      if (container.current) {
        if (e.key === "q") scrollToBuilding("quarry")
        if (e.key === "w") scrollToBuilding("workshop")
        if (e.key === "e") scrollToBuilding("claypit")
        if (e.key === "r") scrollToBuilding("storage")
        if (e.key === "a") scrollToBuilding("lumbercamp")
        if (e.key === " ") scrollToBuilding("home")
      }
    })
    // eslint-disable-next-line
  }, [])

  //const audioRef = useRef<ReactAudioPlayer>()

  const toggleAudio = () => {
    setData(od => ({ ...od, audio: { ...od.audio, muted: !od.audio.muted } }))
  }

  return <div className='w-screen h-screen flex items-center justify-center bg-background bg-pattern-linen'>
    {data.newGame ?
      <NewGame data={data} togglePause={togglePause} isGameRunning={data.time.isGameRunning} setData={setData} />

      : data.dungeon.active ?
        <Dungeon data={data} setData={setData} />

        : data.city.active ?
          <>
            <ScrollContainer className="scrollContainer max-w-full max-h-full relative" draggingClassName="dragging" innerRef={containerCity} onEndScroll={(e) => setLocationCity()} >
              <div className="w-[1250px] h-[1000px] bg-[url('../assets/city.png')] bg-contain relative overflow-hidden" id="map-city">
                <CityMapBuilding building={data.city.buildings.tavern} onClick={() => setTavernOpen(true)} />
                <CityMapBuilding building={data.city.buildings.market} onClick={() => setMarketOpen(true)} />
                <Night timeOfDay={data.time.timeOfDay} />


                <div className="fixed left-2 bottom-2 inline-flex gap-1 flex-col z-50">
                  <IconButton icon={IconMap} onClick={() => { leaveCity() }} >Leave City</IconButton>
                </div>

                <Modal
                  isOpen={tavernOpen}
                  setIsOpen={setTavernOpen}
                  data={data}
                  title="Tavern"
                  hasLight
                >
                  <MenuTavern data={data} setData={setData} />
                </Modal>
                <Modal
                  isOpen={marketOpen}
                  setIsOpen={setMarketOpen}
                  data={data}
                  title="Market"
                >
                  market
                </Modal>
              </div>
            </ScrollContainer>
          </>

          :

          <>
            <ScrollContainer className="scrollContainer max-w-full max-h-full relative" draggingClassName="dragging" innerRef={container} onEndScroll={(e) => setLocation()} >
              <div className="w-[4500px] h-[2000px] bg-grass relative overflow-hidden" id="map-home">

                <img src="/assets/buildings/map/river.png" alt="" className=" pointer-events-none absolute" style={{ left: 2278 / 2, top: -(384 / 2) }} />
                {data.tech.roads.active && <img src="/assets/buildings/map/path.png" alt="" className=" pointer-events-none absolute" style={{ left: 2503 / 2, top: 425 / 2 }} />}
                {data.tech.bridge.active && <img src="/assets/buildings/map/bridge.png" alt="" className="absolute" style={{ left: 3750 / 2, top: 330 / 2 }} onClick={() => visitCity()} />}

                <MapBuilding building={data.buildings.home} onClick={() => setMenuHomeOpen(true)} />
                <MapBuilding building={data.buildings.lumbercamp} onClick={() => setMenuLumbercampOpen(true)} />
                <MapBuilding building={data.buildings.workshop} onClick={() => setMenuWorkshopOpen(true)} />
                <MapBuilding building={data.buildings.claypit} onClick={() => setMenuClaypitOpen(true)} />
                <MapBuilding building={data.buildings.fisher} onClick={() => setMenuFisherOpen(true)} />
                <MapBuilding building={data.buildings.kitchen} onClick={() => setMenuKitchenOpen(true)} />
                <MapBuilding building={data.buildings.storage} onClick={() => setMenuStorageOpen(true)} />
                <MapBuilding building={data.buildings.quarry} onClick={() => setMenuQuarryOpen(true)} />
                <MapBuilding building={data.buildings.herbalist} onClick={() => setMenuHerbalistOpen(true)} />

                <Forest lumbercamp={data.buildings.lumbercamp} />

                <Modal
                  isOpen={menuAssignmentsOpen}
                  setIsOpen={setMenuAssignmentsOpen}
                  data={data}
                  title="Assigments"
                  icon={IconPerson}
                >
                  <MenuAssigments data={data} setData={setData} />
                </Modal>

                <Modal
                  isOpen={menuLumbercampOpen}
                  setIsOpen={setMenuLumbercampOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.lumbercamp} />}
                  bg={bgLumbercamp}>
                  <MenuBuildingProduction
                    building={data.buildings.lumbercamp}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuWorkshopOpen}
                  setIsOpen={setMenuWorkshopOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.workshop} />}
                  bg={bgWorkshop}>
                  <MenuBuildingWorkshop
                    building={data.buildings.workshop}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuClaypitOpen}
                  setIsOpen={setMenuClaypitOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.claypit} />}
                  bg={bgClaypit}>
                  <MenuBuildingProduction
                    building={data.buildings.claypit}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuFisherOpen}
                  setIsOpen={setMenuFisherOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.fisher} />}
                  bg={bgFisher}>
                  <MenuBuildingProduction
                    building={data.buildings.fisher}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuHomeOpen}
                  setIsOpen={setMenuHomeOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.home} />}
                  bg={bgHome}>
                  <MenuBuildingHome
                    building={data.buildings.home}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuKitchenOpen}
                  setIsOpen={setMenuKitchenOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.kitchen} />}
                  bg={bgKitchen}>
                  <MenuBuildingKitchen
                    building={data.buildings.kitchen}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuQuarryOpen}
                  setIsOpen={setMenuQuarryOpen}
                  data={data} title={<BuildingTitle building={data.buildings.quarry} />}
                  bg={bgQuarry}>
                  <MenuBuildingProduction
                    building={data.buildings.quarry}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuStorageOpen}
                  setIsOpen={setMenuStorageOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.storage} />}
                  bg={bgStorage}>
                  <MenuBuildingStorage
                    building={data.buildings.storage}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <Modal
                  isOpen={menuHerbalistOpen}
                  setIsOpen={setMenuHerbalistOpen}
                  data={data}
                  title={<BuildingTitle building={data.buildings.herbalist} />}
                  bg={bgStorage}>
                  <MenuBuildingProduction
                    building={data.buildings.herbalist}
                    data={data}
                    setData={setData}
                  />
                </Modal>
                <img src="/assets/buildings/map/random-trees.png" alt="" className=" pointer-events-none absolute" style={{ left: 61 / 2, top: 36 / 2 }} />
                <Night timeOfDay={data.time.timeOfDay} />
                <div className="fixed left-2 bottom-2 inline-flex gap-1 flex-col z-50">
                  <IconButton icon={IconPerson} onClick={() => { closeAllMenus(); setMenuAssignmentsOpen(true) }}>Assignments</IconButton>
                  <IconButton icon={data.buildings.home.icon} onClick={() => { closeAllMenus(); setMenuHomeOpen(true) }}>Home</IconButton>
                  <IconButton icon={data.buildings.lumbercamp.icon} onClick={() => { closeAllMenus(); setMenuLumbercampOpen(true) }} >Lumbercamp</IconButton>
                  <IconButton icon={data.buildings.workshop.icon} onClick={() => { closeAllMenus(); setMenuWorkshopOpen(true) }} >Workshop</IconButton>
                  <IconButton icon={data.buildings.claypit.icon} onClick={() => { closeAllMenus(); setMenuClaypitOpen(true) }} >Claypit</IconButton>
                  <IconButton icon={data.buildings.fisher.icon} onClick={() => { closeAllMenus(); setMenuFisherOpen(true) }} >Fisher</IconButton>
                  <IconButton icon={data.buildings.kitchen.icon} onClick={() => { closeAllMenus(); setMenuKitchenOpen(true) }} >Kitchen</IconButton>
                  <IconButton icon={data.buildings.storage.icon} onClick={() => { closeAllMenus(); setMenuStorageOpen(true) }} >Storage</IconButton>
                  <IconButton icon={data.buildings.quarry.icon} onClick={() => { closeAllMenus(); setMenuQuarryOpen(true) }} >Quarry</IconButton>
                  <IconButton icon={data.buildings.herbalist.icon} onClick={() => { closeAllMenus(); setMenuHerbalistOpen(true) }} >Herbalist</IconButton>
                  <IconButton icon={IconMap} onClick={() => { visitCity() }} >Visit City</IconButton>
                </div>
              </div>
            </ScrollContainer >
          </>
    }
    <div className="header fixed top-0 left-0 right-0 items-center flex gap-4 text-sm bg-surface bg-pattern-squares shadow-[inset_0_0_8px_rgba(255,255,255,0.1)] text-gray-100 p-1 z-50" id="header">
      <div className="flex items-center space-x-2"><WorkerPortrait noInfo worker={data.player} size="small" /><span>{getFullName(data.player)}</span></div>
      <HeaderDivider />
      <div
        onClick={() => toggleLight()}
        data-tooltip-id={"tooltip-toggleLight"}
        data-tooltip-place="bottom"
      >
        <Icon
          icon={IconTorch}
          className={` transition-opacity duration-300 ${data.lightsOn ? "" : "opacity-30"}`}
        />
        <TooltipCustom id="tooltip-toggleLight">
          <p className="">Toggle light in buildings. Costs <span className=" inline-flex gap-1 items-center">1 <Icon icon={getResourceInfo("wood").icon} size="small" /></span> per hour when active.</p>
          {!data.tech.torches.active && <div className="mt-1">You need to research the <span className="text-primary">{data.tech.torches.name}</span> technology first.</div>}
        </TooltipCustom>
      </div>
      <HeaderDivider />
      {true && (
        <>
          <div onClick={() => enterDungeon()}>
            <Icon icon={IconBattle} />
          </div>
          <HeaderDivider />
        </>
      )}
      <div className="flex items-center gap-1">
        <HeaderResource resource={getResourceInfo("wood")} data={data} />
        <HeaderResource resource={getResourceInfo("stone")} data={data} />
        <HeaderResource resource={getResourceInfo("clay")} data={data} />
        <HeaderResource resource={getResourceInfo("stick")} data={data} />
      </div>
      <HeaderDivider />
      <div className="flex-1" />
      {true && <ReactAudioPlayer
        src="http://settlerino.lancamp.de/music/fantasy_motion.mp3"
        autoPlay
        loop
        volume={data.audio.muted ? 0 : data.audio.volume}
      />}
      <HeaderDivider />
      <div className="" onClick={() => toggleAudio()}>
        {data.audio.muted ? <VolumeX size={24} /> : <Volume size={24} />}
      </div>
      <HeaderDivider />
      <div className="" onClick={() => togglePause()}>{data.time.isGameRunning ? <Pause size={24} /> : <Play size={24} />}</div>
      <HeaderDivider />
      <div className="flex items-center space-x-1 whitespace-nowrap">
        <span>{data.time.timeOfDay}</span><span><span className="w-[2ch] inline-block text-right">{data.time.hour.padNum()}</span>:<span className="w-[2ch] inline-block">{data.time.minute.padNum()}</span></span><Icon icon={getTimeOfDayIcon(data.time.timeOfDay)} />
      </div>
      <HeaderDivider />
      <div onClick={() => { closeAllMenus(); setSettingsOpen(!settingsOpen) }}><Icon icon={IconScroll} /></div>
      <Settings
        isOpen={settingsOpen}
        setIsOpen={setSettingsOpen}
        data={data}
        setData={setData} />
    </div >
    <div id="modals" />
  </div>
}

export default App

const HeaderDivider = () => <div className="w-px h-4 bg-primary block " />

const HeaderResource = ({ resource, data }: { resource: Resource, data: Data }) => (
  <>
    <div
      className="flex gap-2 items-center p-1 bg-surface rounded-sm"
      data-tooltip-id={`header-${resource.type}`}
      data-tooltip-place="bottom"
    >
      <Icon icon={resource.icon} />
      <div className="min-w-[70px] text-center">
        {data.resources[getResourceId(resource)].length}/{getMaxStorage(data.buildings.storage)}
      </div>
    </div>
    <TooltipCustom id={`header-${resource.type}`}>{resource.name}</TooltipCustom>
  </>
)


const MapBuilding = ({
  building,
  onClick,
}: {
  building: Building
  onClick?: () => void
}) => {
  return (
    <img
      src={buildingPath(building)}
      className={`cursor-pointer absolute hover:drop-shadow-[0_0_10px_rgba(0,0,0,0.5)]`}
      data-aos="fade"
      data-aos-duration="1000"
      data-aos-anchor="map-home"
      style={{
        left: building.levels[building.level].coords.x / 2,
        top: building.levels[building.level].coords.y / 2,
      }}
      onClick={onClick}
      alt=""
    />
  )
}


const CityMapBuilding = ({
  building,
  onClick,
}: {
  building: CityBuilding
  onClick?: () => void
}) => {
  return (
    <img
      src={building.image}
      className={`cursor-pointer absolute hover:drop-shadow-[0_0_10px_rgba(0,0,0,0.5)]`}
      data-aos="fade"
      data-aos-duration="1000"
      data-aos-anchor="map-home"
      style={{
        left: building.coords.x / 2,
        top: building.coords.y / 2,
      }}
      onClick={onClick}
      alt=""
    />
  )
}
