import portrait0 from '../assets/portraits/female/0.jpg';
import portrait1 from '../assets/portraits/female/1.jpg';
import portrait2 from '../assets/portraits/female/2.jpg';
import portrait3 from '../assets/portraits/female/3.jpg';
import portrait4 from '../assets/portraits/female/4.jpg';
import portrait5 from '../assets/portraits/female/5.jpg';
import portrait6 from '../assets/portraits/female/6.jpg';
import portrait7 from '../assets/portraits/female/7.jpg';
import portrait8 from '../assets/portraits/female/8.jpg';
import portrait9 from '../assets/portraits/female/9.jpg';
import portrait10 from '../assets/portraits/female/10.jpg';
import portrait11 from '../assets/portraits/female/11.jpg';
import portrait12 from '../assets/portraits/female/12.jpg';
import portrait13 from '../assets/portraits/female/13.jpg';
import portrait14 from '../assets/portraits/female/14.jpg';
import portrait15 from '../assets/portraits/female/15.jpg';
import portrait16 from '../assets/portraits/female/16.jpg';
import portrait17 from '../assets/portraits/female/17.jpg';
import portrait18 from '../assets/portraits/female/18.jpg';
import portrait19 from '../assets/portraits/female/19.jpg';
import portrait20 from '../assets/portraits/female/20.jpg';
import portrait21 from '../assets/portraits/female/21.jpg';
import portrait22 from '../assets/portraits/female/22.jpg';
import portrait23 from '../assets/portraits/female/23.jpg';
import portrait24 from '../assets/portraits/female/24.jpg';
import portrait25 from '../assets/portraits/female/25.jpg';
import portrait26 from '../assets/portraits/female/26.jpg';
import portrait27 from '../assets/portraits/female/27.jpg';
import portrait28 from '../assets/portraits/female/28.jpg';
import portrait29 from '../assets/portraits/female/29.jpg';
import portrait30 from '../assets/portraits/female/30.jpg';
import portrait31 from '../assets/portraits/female/31.jpg';
import portrait32 from '../assets/portraits/female/32.jpg';
import portrait33 from '../assets/portraits/female/33.jpg';
import portrait34 from '../assets/portraits/female/34.jpg';
import portrait35 from '../assets/portraits/female/35.jpg';
import portrait36 from '../assets/portraits/female/36.jpg';
import portrait37 from '../assets/portraits/female/37.jpg';
import portrait38 from '../assets/portraits/female/38.jpg';
import portrait39 from '../assets/portraits/female/39.jpg';
import portrait40 from '../assets/portraits/female/40.jpg';
import portrait41 from '../assets/portraits/female/41.jpg';
import portrait42 from '../assets/portraits/female/42.jpg';
import portrait43 from '../assets/portraits/female/43.jpg';
import portrait44 from '../assets/portraits/female/44.jpg';
import portrait45 from '../assets/portraits/female/45.jpg';
import portrait46 from '../assets/portraits/female/46.jpg';
import portrait47 from '../assets/portraits/female/47.jpg';
import portrait48 from '../assets/portraits/female/48.jpg';
import portrait49 from '../assets/portraits/female/49.jpg';
import portrait50 from '../assets/portraits/female/50.jpg';
import portrait51 from '../assets/portraits/female/51.jpg';
import portrait52 from '../assets/portraits/female/52.jpg';
import portrait53 from '../assets/portraits/female/53.jpg';
import portrait54 from '../assets/portraits/female/54.jpg';
import portrait55 from '../assets/portraits/female/55.jpg';
import portrait56 from '../assets/portraits/female/56.jpg';
import portrait57 from '../assets/portraits/female/57.jpg';
import portrait58 from '../assets/portraits/female/58.jpg';
import portrait59 from '../assets/portraits/female/59.jpg';
import portrait60 from '../assets/portraits/female/60.jpg';
import portrait61 from '../assets/portraits/female/61.jpg';
import portrait62 from '../assets/portraits/female/62.jpg';
import portrait63 from '../assets/portraits/female/63.jpg';
import portrait64 from '../assets/portraits/female/64.jpg';
import portrait65 from '../assets/portraits/female/65.jpg';
import portrait66 from '../assets/portraits/female/66.jpg';
import portrait67 from '../assets/portraits/female/67.jpg';
import portrait68 from '../assets/portraits/female/68.jpg';
import portrait69 from '../assets/portraits/female/69.jpg';
import portrait70 from '../assets/portraits/female/70.jpg';
import { getRandomNumber } from '../helper/mathHelper';

export const femalePortraits = [
  portrait0, portrait1, portrait2, portrait3, portrait4, portrait5,
  portrait6, portrait7, portrait8, portrait9, portrait10, portrait11,
  portrait12, portrait13, portrait14, portrait15, portrait16, portrait17,
  portrait18, portrait19, portrait20, portrait21, portrait22, portrait23,
  portrait24, portrait25, portrait26, portrait27, portrait28, portrait29,
  portrait30, portrait31, portrait32, portrait33, portrait34, portrait35,
  portrait36, portrait37, portrait38, portrait39, portrait40, portrait41,
  portrait42, portrait43, portrait44, portrait45, portrait46, portrait47,
  portrait48, portrait49, portrait50, portrait51, portrait52, portrait53,
  portrait54, portrait55, portrait56, portrait57, portrait58, portrait59,
  portrait60, portrait61, portrait62, portrait63, portrait64, portrait65,
  portrait66, portrait67, portrait68, portrait69, portrait70,
];

export const getRandomFemalePortrait = () => femalePortraits[getRandomNumber(0, femalePortraits.length - 1)]