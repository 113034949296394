import Axe from "../assets/icons/axe.png"
import Clay from "../assets/icons/clay.png"
import CopperIngot from "../assets/icons/copperIngot.png"
import CopperOre from "../assets/icons/copperOre.png"
import GoldIngot from "../assets/icons/goldIngot.png"
import GoldOre from "../assets/icons/goldOre.png"
import IronIngot from "../assets/icons/ironIngot.png"
import IronOre from "../assets/icons/ironOre.png"
import SilverIngot from "../assets/icons/silverIngot.png"
import SilverOre from "../assets/icons/silverOre.png"
import Stone from "../assets/icons/stone.png"
import Wood from "../assets/icons/wood.png"
import Apple from "../assets/icons/apple.png"
import ArrowBentLeft from "../assets/icons/arrow-bent-left.png"
import ArrowUp from "../assets/icons/arrowUp.png"
import Refresh from "../assets/icons/arrowsCw.png"
import Battle from "../assets/icons/battle.png"
import Book from "../assets/icons/book.png"
import Bread from "../assets/icons/bread.png"
import Brick from "../assets/icons/brick.png"
import Carrot from "../assets/icons/carrot.png"
import Cheese from "../assets/icons/cheese.png"
import Chest from "../assets/icons/chest.png"
import Chisel from "../assets/icons/chisel.png"
import Claypit from "../assets/icons/claypit.png"
import Coal from "../assets/icons/coal.png"
import Coin from "../assets/icons/coin.png"
import Fish from "../assets/icons/fish.png"
import FriedFish from "../assets/icons/friedFish.png"
import Gemstone from "../assets/icons/gemstone.png"
import Glass from "../assets/icons/glass.png"
import Hammer from "../assets/icons/hammer.png"
import Herb from "../assets/icons/herb.png"
import Hoe from "../assets/icons/hoe.png"
import Knife from "../assets/icons/knife.png"
import Leather from "../assets/icons/leather.png"
import Log from "../assets/icons/log.png"
import Manure from "../assets/icons/manure.png"
import Milk from "../assets/icons/milk.png"
import Garlic from "../assets/icons/garlic.png"
import Onion from "../assets/icons/garlic.png"
import Peat from "../assets/icons/peat.png"
import Person from "../assets/icons/person.png"
import Pickaxe from "../assets/icons/pickaxe.png"
import Poultice from "../assets/icons/poultice.png"
import Quarry from "../assets/icons/quarry.png"
import Road from "../assets/icons/road.png"
import Saw from "../assets/icons/saw.png"
import ScrollOpen from "../assets/icons/scroll-open.png"
import Scroll from "../assets/icons/scroll.png"
import Scythe from "../assets/icons/scythe.png"
import Shears from "../assets/icons/shears.png"
import Shovel from "../assets/icons/shovel.png"
import Sparkle from "../assets/icons/sparkle.png"
import Tent from "../assets/icons/tent.png"
import Tincture from "../assets/icons/tincture.png"
import Torch from "../assets/icons/torch.png"
import Trowel from "../assets/icons/trowel.png"
import WillowBark from "../assets/icons/root.png"
import Root from "../assets/icons/root.png"
import Wool from "../assets/icons/wool.png"
import Sun from "../assets/icons/sun.png"
import Sunset from "../assets/icons/sunset.png"
import Sunrise from "../assets/icons/sunrise.png"
import Moon from "../assets/icons/moon.png"
import Lumbercamp from "../assets/icons/lumbercamp2.png"
import Play from "../assets/icons/play.png"
import Pause from "../assets/icons/pause.png"
import Harp from "../assets/icons/harp.png"
import Violin from "../assets/icons/violin.png"
import Flute from "../assets/icons/flute.png"
import PanFlute from "../assets/icons/pan-flute.png"
import Fishingrod from "../assets/icons/fishingrod.png"
import Basket from "../assets/icons/basket.png"
import Heal from "../assets/icons/heal.png"
import Run from "../assets/icons/run.png"
import Bandage from "../assets/icons/bandage.png"
import ZZZ from "../assets/icons/zzz.png"
import Hourglass from "../assets/icons/hourglass.png"
import KitchenUtensils from "../assets/icons/kitchenUtensils.png"
import Backpack from "../assets/icons/backpack.png"
import Kitchen from "../assets/icons/kitchen.png"
import Mushrooms from "../assets/icons/mushrooms.png"
import Bridge from "../assets/icons/bridge.png"
import Map from "../assets/icons/map.png"
import GetPaid from "../assets/icons/getPaid.png"
import Pay from "../assets/icons/pay.png"

import { TimeOfDay } from "../types/types"

export const IconTorch = Torch
export const IconChest = Chest
export const IconBattle = Battle
export const IconArrowUp = ArrowUp
export const IconPerson = Person
export const IconScrollOpen = ScrollOpen
export const IconScroll = Scroll
export const IconArrowBentLeft = ArrowBentLeft
export const IconRefresh = Refresh
export const IconLumbercamp = Lumbercamp
export const IconQuarry = Quarry
export const IconTent = Tent
export const IconClaypit = Claypit
export const IconBook = Book
export const IconRoad = Road
export const IconWool = Wool;
export const IconLeather = Leather;
export const IconManure = Manure;
export const IconWood = Wood;
export const IconStone = Stone;
export const IconClay = Clay;
export const IconCoal = Coal;
export const IconPeat = Peat;
export const IconSparkle = Sparkle;
export const IconFish = Fish;
export const IconFriedFish = FriedFish;
export const IconBread = Bread;
export const IconCheese = Cheese;
export const IconApple = Apple;
export const IconCarrot = Carrot;
export const IconOnion = Onion;
export const IconGarlic = Garlic;
export const IconMilk = Milk;
export const IconCopperIngot = CopperIngot;
export const IconIronIngot = IronIngot;
export const IconSilverIngot = SilverIngot;
export const IconGoldIngot = GoldIngot;
export const IconHerb = Herb;
export const IconWillowBark = WillowBark;
export const IconRoot = Root;
export const IconTincture = Tincture;
export const IconPoultice = Poultice;
export const IconIronOre = IronOre;
export const IconCopperOre = CopperOre;
export const IconSilverOre = SilverOre;
export const IconGoldOre = GoldOre;
export const IconBrick = Brick;
export const IconGlass = Glass;
export const IconLog = Log;
export const IconCoin = Coin;
export const IconGemstone = Gemstone;
export const IconAxe = Axe;
export const IconChisel = Chisel;
export const IconHammer = Hammer;
export const IconHoe = Hoe;
export const IconKnife = Knife;
export const IconPickaxe = Pickaxe;
export const IconSaw = Saw;
export const IconScythe = Scythe;
export const IconShears = Shears;
export const IconShovel = Shovel;
export const IconTrowel = Trowel;
export const IconSun = Sun;
export const IconSunrise = Sunrise;
export const IconSunset = Sunset;
export const IconMoon = Moon;
export const IconPlay = Play
export const IconPause = Pause
export const IconHarp = Harp
export const IconViolin = Violin
export const IconFlute = Flute
export const IconPanFlute = PanFlute
export const IconFishingRod = Fishingrod
export const IconBasket = Basket
export const IconBandage = Bandage
export const IconHeal = Heal
export const IconRun = Run
export const IconZzz = ZZZ
export const IconHourglass = Hourglass
export const IconKitchenUtensils = KitchenUtensils
export const IconBackpack = Backpack
export const IconKitchen = Kitchen
export const IconMushrooms = Mushrooms
export const IconBridge = Bridge
export const IconMap = Map
export const IconGetPaid = GetPaid
export const IconPay = Pay

export const getTimeOfDayIcon = (timeOfDay: TimeOfDay): string => {
  switch (timeOfDay) {
    case "Early Morning":
      return IconSunrise
    case "Late Morning":
      return IconSun
    case "Noon":
      return IconSun
    case "Early Afternoon":
      return IconSun
    case "Late Afternoon":
      return IconSun
    case "Evening":
      return IconSunset
    case "Night":
      return IconMoon
  }
}