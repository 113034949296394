import { IconWool, IconLeather, IconManure } from "../../data/icons";
import { createId } from "../../helper/stringHelper";
import { Resource } from "../../types/types";

// Wool
export const resourceWool: Resource = {
  id: createId(),
  name: "Wool",
  type: "wool",
  rarity: "common",
  
  icon: IconWool,
  byproduct: undefined,
  category: "animal product",
  
  tradeValue: 1,
}

// Hide
export const resourceHide: Resource = {
  id: createId(),
  name: "Hide",
  type: "hide",
  rarity: "common",
  
  icon: IconLeather,
  byproduct: undefined,
  category: "animal product",
  
  tradeValue: 1,
};

// Manure
export const resourceManure: Resource = {
  id: createId(),
  name: "Manure",
  type: "manure",
  rarity: "common",
  
  icon: IconManure,
  byproduct: undefined,
  category: "animal product",
  
  tradeValue: 1,
};

// Export const for referencing all Animal Product resources
export const animalProductResources: Resource[] = [resourceWool, resourceHide, resourceManure];

