import { Enemy } from "./dungeon";


export type ResourceCategory = "Animal Product" | "Basic" | "Energy" | "Food" | "Ingot" | "Medicine" | "Ore" | "Processed" | "Treasury"

export type Rarity = "common" | "uncommon" | "rare" | "epic" | "legendary"

export type ToolType = "axe" | "chisel" | "hammer" | "hoe" | "knife" | "pickaxe" | "scythe" | "saw" | "shears" | "sickle" | "shovel" | "trowel" | "wheelbarrow" | "fishingRod" | "kitchenUtensils" | "backpack"
export type MaterialType = "copperAxe" | "ironAxe" |
  "copperChisel" | "ironChisel" |
  "woodenHammer" | "copperHammer" | "ironHammer" |
  "woodenHoe" | "copperHoe" | "ironHoe" |
  "stoneKnife" | "copperKnife" | "ironKnife" |
  "copperPickaxe" | "ironPickaxe" |
  "copperSaw" | "ironSaw" |
  "copperScythe" | "ironScythe" |
  "copperShears" | "ironShears" |
  "woodenShovel" | "copperShovel" | "ironShovel" |
  "woodenTrowel" | "copperTrowel" | "ironTrowel" |
  "woodenFishingRod" |
  "woodenKitchenUtensils" |
  "leatherBackpack"

export type ResourceType =
  "stick" |
  "wood" | "stone" | "clay" |
  "wool" | "hide" | "manure" |
  "coal" | "peat" | "charcoal" | "oil" |
  "bread" | "cheese" | "apple" | "carrot" | "onion" | "milk" | "fish" | "mushroom" |
  "mushroomSoup" | "friedFish" |
  "ironIngot" | "copperIngot" | "silverIngot" | "goldIngot" |
  "herb" | "healingPoultice" | "medicinalTincture" | "willowBark" |
  "ironOre" | "copperOre" | "silverOre" | "goldOre" |
  "brick" | "glass" | "leather" | "woodenNail" | "copperNail" | "ironNail" | "log" |
  "goldCoin" | "gemstone"

export type BuildingType = "home" | "storage" | "quarry" | "lumbercamp" | "claypit" | "workshop" | "fisher" | "kitchen" | "herbalist"
export type PerkType = "woodcutting" | "mining" | "extraction" | "storage" | "crafting" | "fishing" | "cooking" | "herbalist"

export type TimeOfDay = "Early Morning" | "Late Morning" | "Noon" | "Early Afternoon" | "Late Afternoon" | "Evening" | "Night"

export type TechType = "roads" | "torches" | "bridge"

export interface Worker {
  id: string;
  name?: string;
  lastName?: string;
  nickname?: string;
  portrait?: string;
  sex?: Sex;
  health: { current: number; max: number };
  stamina: { current: number; max: number };
  strength: number;
  hunger: { current: number; max: number };
  thirst: { current: number; max: number };
  happiness: { current: number; max: number }
  lastFood?: ResourceType
  workProgress: number[]
  resting: boolean
  wage?: number
  perks: {
    woodcutting: Perk
    mining: Perk
    storage: Perk
    extraction: Perk
    crafting: Perk
    fishing: Perk
    cooking: Perk
    herbalist: Perk
  }
  inventory: {
    tools: {
      axe?: Axe
      chisel?: Chisel
      hammer?: Hammer
      hoe?: Hoe
      knife?: Knife
      pickaxe?: Pickaxe
      saw?: Saw
      scythe?: Scythe
      shears?: Shears
      sickle?: Sickle
      shovel?: Shovel
      trowel?: Trowel
      wheelbarrow?: Wheelbarrow
      fishingRod?: FishingRod
      kitchenUtensils?: KitchenUtensils
      backpack?: Backpack
    }
  }
}

export interface Player extends Worker { }

export type Sex = "male" | "female"

export interface SkillTree {
  // Define your skill tree properties here
  // Example: strength, speed, precision, etc.
}

export interface InventoryItem {
  item: Tool
  quantity: number
}

export interface Resource {
  id: string
  type: ResourceType
  name: string
  rarity: Rarity
  category: string
  icon: string
  byproduct?: ResourceByproduct
  tradeValue?: number
  craftingCost?: Cost[]
}

export interface Food extends Resource {
  status: Status;
  /* raw: boolean */
  satisfaction: number
  reduceHunger: number
}

export interface CookedFood extends Food {
  craftingCost: Cost[]
  time: { current: number, max: number }
}

export interface Ingredient extends Resource {
  status: Status
}

export interface Fuel extends Resource {
  status: Status;
}

interface Status {
  max: number
  current: number
}

export interface Perk {
  level: number
  exp: number
}

interface ResourceByproduct {
  resource: Resource
  amount: number
}

export interface Cost {
  resource: Resource
  amount: number
}
export interface Tool {
  id: string
  name: string
  rarity: Rarity
  materialType: MaterialType
  icon: string
  efficiency: number
  type: ToolType
  craftingCost: Cost[]
  status: Status
  tradeValue?: number
  available?: boolean
  time: number // production time in milliseconds
}

export interface Hammer extends Tool { }
export interface Shovel extends Tool { }
export interface Pickaxe extends Tool { }
export interface Scythe extends Tool { }
export interface Axe extends Tool { }
export interface Hoe extends Tool { }
export interface Saw extends Tool { }
export interface Chisel extends Tool { }
export interface Sickle extends Tool { }
export interface Trowel extends Tool { }
export interface Knife extends Tool { }
export interface Shears extends Tool { }
export interface Wheelbarrow extends Tool { }
export interface FishingRod extends Tool { }
export interface KitchenUtensils extends Tool { }
export interface Backpack extends Tool { }

export interface Building {
  id: BuildingType,
  name: string
  workers: Worker[]
  level: number
  levels: BuildingLevel[]
  icon: string
  description: string
}

export interface BuildingProduction extends Building {
  resourceProduced: ResourceProduced[]
  //storage: { max: number, resources: Resource[] }
}
export interface ResourceProduced {
  resource: Resource
  quantity: { current: number; max: number }
  regeneration: { current: number; rate: number }
  progress: { current: number; needed: number }
}
export interface BuildingFisher extends BuildingProduction { }

export interface BuildingHerbalist extends BuildingProduction { }

export const forestData = {
  treeAmount: 250,
  width: 4700,
  height: 500,
  x: -100,
  y: 1500,
  excludedArea: {
    x: 700,
    y: 0,
    width: 700,
    height: 150,
  }
}
export interface BuildingLumbercamp extends BuildingProduction {
  trees: TreeInterface[]
}
export interface TreeInterface {
  tree: string
  position: TreePosition;
}
export interface TreePosition {
  x: number;
  y: number;
}
export interface BuildingStorage extends Building {
  storage: number
}

export interface Recipe {
  id: string;
  name: string;
  inputResources: { resource: Resource; amount: number }[];
  outputResources: { resource: Tool; amount: number }[];
  duration: number; // Duration in seconds
}

export interface BuildingWorkshop extends Building {
  recipes: Tool[];
  selectedRecipe?: Tool;
  toolToProduce?: Tool;
  selectedResearch?: Tech
  techToResearch?: Tech
}
export interface BuildingHome extends Building { }

export interface BuildingKitchen extends Building {
  recipes: CookedFood[]
  selectedRecipes: CookedFood[]
}

export interface BuildingHerbalist extends Building { }

export interface BuildingLevel {
  level: number
  efficiency: number
  coords: { x: number, y: number }
  cost?: BuildingUpgradeCost[]
  jobs: number
}

interface BuildingUpgradeCost {
  resource: Resource
  amount: number
}

export interface CityBuilding {
  id: string
  name: string
  image: string
  coords: { x: number, y: number }
}

export interface TavernBuilding extends CityBuilding {
  patrons: Worker[]
  lastVisited: TimeOfDay
}

export interface Data {
  version: string
  newGame: boolean
  player: Player
  location: {
    x: number
    y: number
  }
  buildings: {
    home: BuildingHome
    storage: BuildingStorage
    quarry: BuildingProduction
    lumbercamp: BuildingLumbercamp
    claypit: BuildingProduction
    workshop: BuildingWorkshop
    fisher: BuildingFisher
    kitchen: BuildingKitchen
    herbalist: BuildingHerbalist
  }
  toolItems: Tool[]
  resources: {
    stick: Resource[],
    wood: Resource[],
    stone: Resource[],
    clay: Resource[],

    copperIngot: Resource[],
    wool: Resource[],
    hide: Resource[],
    manure: Resource[],

    coal: Resource[],
    peat: Resource[],
    charcoal: Resource[],
    oil: Resource[],

    fish: Resource[],
    mushroom: Resource[]

    friedFish: Resource[],
    mushroomSoup: Resource[],
    bread: Resource[],
    cheese: Resource[],
    apple: Resource[],
    carrot: Resource[],
    onion: Resource[],
    milk: Resource[],

    ironIngot: Resource[],
    silverIngot: Resource[],
    goldIngot: Resource[],

    herb: Resource[],
    healingPoultice: Resource[],
    medicinalTincture: Resource[],
    willowBark: Resource[],

    ironOre: Resource[],
    copperOre: Resource[],
    silverOre: Resource[],
    goldOre: Resource[],

    brick: Resource[],
    glass: Resource[],
    leather: Resource[],
    woodenNail: Resource[],
    copperNail: Resource[],
    ironNail: Resource[],
    log: Resource[],

    goldCoin: Resource[],
    gemstone: Resource[],
  }
  tech: {
    torches: Tech
    roads: Tech
    bridge: Tech
  }
  lightsOn: boolean
  workers: Worker[]
  unemployedWorkers: Worker[]
  time: {
    isGameRunning: boolean
    speed: number
    hour: number
    minute: number
    isDaytime: boolean
    timeOfDay: TimeOfDay
    isWorktime: boolean
  }
  city: {
    active: boolean
    buildings: {
      tavern: TavernBuilding
      market: CityBuilding
    }
  }
  dungeon: {
    active: boolean
    currentEnemies: Enemy[]
    log: string[]
    background: number
  }
  audio: {
    volume: number
    muted: boolean
  }
}

export interface Tech {
  name: string
  id: TechType
  icon: string
  cost: TechCost[]
  level: number
  description: string
  progress: {
    current: number
    max: number
  }
  active: boolean
}

interface TechCost {
  resource: Resource
  amount: number
}