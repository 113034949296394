import { bgQuarry } from "../../data/backgrounds"
import { BuildingProduction } from "../../types/types"
import { Icon } from "../Icon"
import ProgressBar from "../Progressbar"
import TooltipCustom from "../TooltipCustom"
import WorkerPortrait from "../WorkerPortrait"
import MenuBuilding, { MenuBuildingProps } from "./MenuBuilding"


interface MenuBuildingProductionProps extends MenuBuildingProps {
  building: BuildingProduction
}

const MenuBuildingProduction = ({
  data,
  setData,
  building,
  bg,
}: MenuBuildingProductionProps) => {


  return (
    <MenuBuilding
      building={building}
      data={data}
      setData={setData}
      bg={bgQuarry}
    >
      <div className="grid grid-cols-4 gap-2">
        {building.resourceProduced.map(resource => {
          return (
            <div className="surface-box flex gap-1 flex-col" data-tooltip-id="production-building-resources">
              <div className="flex items-center gap-2">
                <Icon icon={resource.resource.icon} />{resource.quantity.current}/{resource.quantity.max}
              </div>
              <ProgressBar size="small" current={resource.regeneration.current} max={1} />
            </div>
          )
        })}
      </div>
      <TooltipCustom id="production-building-resources">
        Available Resources
      </TooltipCustom>
      {building.workers && building.workers.length > 0 && (
        <div className="grid grid-cols-2 gap-2 w-full">
          {building.workers.map((worker) => {
            return (
              <div className="flex gap-2 w-full surface-box">
                <WorkerPortrait worker={worker} building={building} isResting={!data.time.isWorktime} />
                <div className="grid grid-cols-2 gap-2 w-full">
                  {building.resourceProduced.map((resource, i) => (
                    <div className="flex items-center gap-1 relative">
                      <Icon icon={resource.resource.icon} className="absolute left-1" />
                      <ProgressBar current={worker.workProgress[i]} size="big" max={resource.progress.needed} />
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      )}
      
    </MenuBuilding>
  )
}



export default MenuBuildingProduction