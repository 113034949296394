import { ReactNode, useState } from "react"
import { createId } from "../helper/stringHelper"
import { Rarity } from "../types/types"
import ConditionalWrapper from "./ConditionalWrapper"
import { Icon } from "./Icon"
import TooltipCustom from "./TooltipCustom"

type ButtonType = "normal" | "fancy" | "transparent" | "icon" | "disabled"

const Button = ({
  children,
  onClick,
  type = "normal",
  icon,
  disabled = false,
}: {
  children?: ReactNode
  onClick?: () => void
  type?: ButtonType
  icon?: string
  disabled?: boolean
}) => {
  if (disabled) type = "disabled"
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`button button-${type}
          ${icon && children ? "pl-2" : ""}
        `}
    >
      {icon && <Icon icon={icon} />}
      {children && <ConditionalWrapper condition={icon != null} wrapper={(children: ReactNode) => <span>{children}</span>}>
        {children}
      </ConditionalWrapper>}
    </button>
  )
}

export default Button

export const ButtonGroup = ({
  children,
  className = ""
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      {children}
    </div>
  )
}

export const IconButton = ({
  icon,
  onClick,
  children,
  progress,
  transparent = false,
  active = false,
  disabled = false,
  color,
}: {
  icon: string
  onClick?: () => void
  children?: ReactNode
  progress?: { current: number, max: number }
  transparent?: boolean
  active?: boolean
  disabled?: boolean
  color?: Rarity
}) => {
  const [id] = useState(createId())
  const border = `border-${color || "primary"}`
  return (
    <>
      <button
        data-tooltip-id={id}
        data-tooltip-place="right"
        className={`w-12 h-12 rounded-sm inline-flex items-center justify-center overflow-hidden relative ${transparent ? "" : `border ${border} bg-primary-dark`} ${active ? "shadow-[inset_0_0_10px_rgba(0,0,0,0.5)]" : ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon icon={icon} />
        {progress && <div className="absolute left-0 right-0 bottom-0 bg-black bg-opacity-50 transition-all duration-500" style={{ height: ((progress.current / progress.max) * 100) + "%" }} />}
      </button>
      {children && <TooltipCustom id={id}>
        {children}
      </TooltipCustom>}
    </>
  )
}