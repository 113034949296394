import { Dispatch, SetStateAction } from "react";
import { hour } from "../data/_data";
import { resourceStick, resourceStone } from "../items/resources/basic";
import { resourceApple } from "../items/resources/food";
import { BuildingProduction, BuildingWorkshop, Data, Worker } from "../types/types";
import { getBuildingId, getEfficiency, getMaxStorage, getResourceId } from "./dataHelper";
import { createId } from "./stringHelper";

export const runWorkshopProduction = async (
  data: Data,
  setData: React.Dispatch<React.SetStateAction<Data>>
) => {
  const d = { ...data }
  const workshop = d.buildings.workshop
  let toolToProduce = workshop.toolToProduce
  const selectedRecipe = workshop.selectedRecipe

  let manpower = 0
  workshop.workers.forEach(worker => manpower += worker.perks.crafting.level)
  const workshopEfficiency = workshop.levels[workshop.level].efficiency

  let techToResearch = workshop.techToResearch
  const selectedResearch = workshop.selectedResearch

  if (!techToResearch) {
    if (selectedResearch) {
      const hasEnoughResources = selectedResearch.cost.every((cost) => {
        const resourceId = getResourceId(cost.resource);
        return d.resources[resourceId].length >= cost.amount;
      });
      if (hasEnoughResources) {
        selectedResearch.cost.forEach((cost) => {
          const resourceId = getResourceId(cost.resource);
          d.resources[resourceId].splice(0, cost.amount);
        })
        workshop.techToResearch = d.tech[selectedResearch.id]
      }
    }
  } else {
    if (techToResearch.progress.current < techToResearch.progress.max) techToResearch.progress.current = techToResearch.progress.current + (manpower * workshopEfficiency)
    else {
      d.tech[techToResearch.id].active = true
      workshop.techToResearch = undefined
      workshop.selectedResearch = undefined
    };
  }

  if (!toolToProduce) {
    if (selectedRecipe) {
      const hasEnoughResources = selectedRecipe.craftingCost.every((cost) => {
        const resourceId = getResourceId(cost.resource);
        return d.resources[resourceId].length >= cost.amount;
      });

      if (hasEnoughResources) {
        selectedRecipe.craftingCost.forEach((cost) => {
          const resourceId = getResourceId(cost.resource);
          d.resources[resourceId].splice(0, cost.amount);
        })

        toolToProduce = {
          id: createId(),
          name: selectedRecipe.name,
          rarity: selectedRecipe.rarity,
          materialType: selectedRecipe.materialType,
          icon: selectedRecipe.icon,
          efficiency: selectedRecipe.efficiency,
          type: selectedRecipe.type,
          craftingCost: selectedRecipe.craftingCost,
          available: true, // Assuming it's available after production
          tradeValue: selectedRecipe.tradeValue,
          time: selectedRecipe.time,
          status: { max: selectedRecipe.status.max, current: selectedRecipe.status.current }
        };

        d.buildings.workshop.toolToProduce = toolToProduce

      } else {
        console.log(`Not enough resources to produce ${selectedRecipe.name}!`);
        d.buildings.workshop.selectedRecipe = undefined
      }
    }
  } else {
    if (toolToProduce.time > 0) toolToProduce.time = toolToProduce.time - (manpower * workshopEfficiency)
    else {
      d.buildings.workshop.toolToProduce = undefined
      toolToProduce.available = true;
      d.toolItems.push(toolToProduce);
    };
  }
  setData(d);
};

export const gatherResource = async (
  worker: Worker,
  building: BuildingProduction,
  data: Data,
  setData: React.Dispatch<React.SetStateAction<Data>>
) => {
  const d = { ...data };

  const buildingData = d.buildings[getBuildingId(building)] as BuildingProduction;
  const efficiency = getEfficiency(worker, building);
  const dataWorker = d.workers.find((w) => w.id === worker.id)!;

  const maxStorage = getMaxStorage(d.buildings.storage)

  let isGatheringResources = false; // Flag to track if the worker is gathering any resources

  if (efficiency > 0 && worker.stamina.current > 0 && d.time.isWorktime) {
    buildingData.resourceProduced.forEach((resourceProducedInfo, index) => {
      //const resourceType = resourceProducedInfo.resource.type as ResourceType;
      const resourceData = d.resources[getResourceId(resourceProducedInfo.resource)];
      const spaceAvailable = maxStorage - resourceData.length;

      // Initialize workProgress for this resource if it doesn't exist
      if (dataWorker.workProgress[index] === undefined) {
        dataWorker.workProgress[index] = 0;
      }

      // Check for resource regeneration
      if (resourceProducedInfo.quantity.current < resourceProducedInfo.quantity.max) {
        resourceProducedInfo.regeneration.current += resourceProducedInfo.regeneration.rate;
        if (resourceProducedInfo.regeneration.current >= 1) {
          const regenerateAmount = Math.floor(resourceProducedInfo.regeneration.current);
          resourceProducedInfo.quantity.current = Math.min(resourceProducedInfo.quantity.current + regenerateAmount, resourceProducedInfo.quantity.max);
          resourceProducedInfo.regeneration.current -= regenerateAmount;
        }
      }

      // Gathering resources based on progress
      if (spaceAvailable > 0 && resourceProducedInfo.quantity.current > 0) {
        dataWorker.workProgress[index] += building.levels[building.level].efficiency * efficiency;
        if (dataWorker.workProgress[index] >= resourceProducedInfo.progress.needed) {
          const gatheredAmount = Math.min(resourceProducedInfo.quantity.current, spaceAvailable, 1);
          resourceData.push({ ...resourceProducedInfo.resource, id: createId() });

          resourceProducedInfo.quantity.current -= gatheredAmount;
          dataWorker.workProgress[index] = 0; // Reset work progress for this resource
        }

        isGatheringResources = true; // Worker is gathering resources
      }
    });
  }

  // Check if the worker is not gathering any resources or other conditions are not met, then make the worker rest
  if (!isGatheringResources || worker.stamina.current <= 0 || !d.time.isWorktime) {
    if (worker.stamina.current < worker.stamina.max) {
      worker.stamina.current = Math.min(worker.stamina.current + (1 * worker.strength), worker.stamina.max);
      if (worker.stamina.current > worker.stamina.max / 2) worker.resting = false
    }
  } else {

    // Update stamina
    const staminaDecrease = 0.2;
    dataWorker.stamina.current = Math.max(0, dataWorker.stamina.current - staminaDecrease);
    if (dataWorker.stamina.current <= 0) {
      worker.resting = true;
    }
  }

  // Update the state with the modified data
  setData(d);
};

export const runProductionBuilding = async (
  building: BuildingProduction,
  data: Data,
  setData: React.Dispatch<React.SetStateAction<Data>>
) => {
  const d = { ...data };

  const buildingId = getBuildingId(building);

  // Handle specific building types with unique production processes
  if (buildingId === "workshop") {
    const workshop: BuildingWorkshop = building as unknown as BuildingWorkshop;
    // If a recipe is selected, run the selected recipe
    if (workshop.selectedRecipe) {
      runWorkshopProduction(data, setData);
    }
  } else if (["quarry", "claypit", "lumbercamp", "fisher", "herbalist"].includes(buildingId)) {
    // Gather resources with workers
    building.workers.forEach(worker => gatherResource(worker, building, data, setData));

    // Regenerate resources for each resourceProduced in the building
    building.resourceProduced.forEach(resourceProduced => {
      if (resourceProduced.quantity.current < resourceProduced.quantity.max) {
        // Increment current regeneration progress
        resourceProduced.regeneration.current += resourceProduced.regeneration.rate;

        // Check if regeneration progress has reached or exceeded 1
        while (resourceProduced.regeneration.current >= 1) {
          // Ensure we do not exceed max quantity
          if (resourceProduced.quantity.current < resourceProduced.quantity.max) {
            resourceProduced.quantity.current += 1; // Increment resource quantity by 1 for each full unit of regeneration progress
            resourceProduced.regeneration.current -= 1; // Decrease regeneration progress by 1 unit
          } else {
            // If max quantity is reached, break from the loop
            break;
          }
        }
      }
    });
  }

  // Update the state with the modified data
  setData(d);
};

export const unemployedWork = async (data: Data, setData: Dispatch<SetStateAction<Data>>) => {
  const d = { ...data }

  const resources = [{
    resource: resourceApple,
    time: hour / 2,
  }, {
    resource: resourceStick,
    time: hour / 4,
  }, {
    resource: resourceStone,
    time: hour / 2,
  }]

  d.unemployedWorkers.forEach(worker => {
    resources.forEach((resource, index) => {
      const spaceAvailable = getMaxStorage(d.buildings.storage) - d.resources[resource.resource.type].length

      if (worker.workProgress[index] === undefined) {
        worker.workProgress[index] = 0;
      }

      if (spaceAvailable > 0 && d.time.isWorktime) {

        worker.workProgress[index] += 0.1 * 1;


        if (worker.workProgress[index] >= resource.time) {
          d.resources[resource.resource.type].push({ ...resource.resource, id: createId() });

          worker.workProgress[index] = 0; // Reset work progress for this resource
        }
      }

    })


  })
  setData(d)
}