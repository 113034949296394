import { ReactNode } from "react";
import { Resource, Tool } from "../types/types";
import TooltipCustom from "./TooltipCustom";

interface IconProps {
  icon: string;
  size?: "small" | "medium" | "big"
  className?: string
  onClick?: () => void
}

export const Icon = ({
  icon,
  size = "medium",
  className = "",
  onClick,
}: IconProps) => <img src={icon} alt="" className={`object-contain flex-shrink-0 ${size === "small" ? "w-4 h-4" : size === "medium" ? "w-6 h-6" : "h-8 w-8"} ${className}`} onClick={onClick} />

interface IconTileProps {
  item: Tool | Resource
  children: ReactNode
  size?: "small" | "medium" | "big"
  onClick?: () => void
}

export const IconTile = ({
  item,
  size = "medium",
  onClick,
  children,
}: IconTileProps) => {
  return (
    <>
      <div className={`aspect-square p-1 border border-${item.rarity} bg-surface rounded-sm`} data-tooltip-id={`icon-tile-${item.type}`} onClick={onClick}>
        <Icon icon={item.icon} size={size} />
      </div>
      <TooltipCustom id={`icon-tile-${item.type}`}>{children}</TooltipCustom>
    </>
  )
}