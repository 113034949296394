import { Rarity } from "../types/types"

export const minute = 1
export const hour = minute * 60
export const day = hour * 24
export const week = day * 7
export const month = day * 30



export const getRarity = (rarity: Rarity) => {
  switch (rarity) {
    case "common":
      return 1
    case "uncommon":
      return 2
    case "rare":
      return 3
    case "epic":
      return 4
    case "legendary":
      return 5
  }
};